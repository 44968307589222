import React, { useEffect, useState } from "react";
import { Button, Form, Select, Layout, Menu, theme, Flex, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;
const { Option } = Select;

const TaskEdit = () => {
  const { id } = useParams();
  const [taskuser, setTaskUser] = useState([]);
  const [selectedTask, setSelectedTask] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [userData, setUserData] = useState([]);
  const [taskData, settaskData] = useState([]);

  const [data, setTaskData] = useState({
    id: id,
    userId: "",
    taskId: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const handleSave = async () => {
    try {
      const selectedTaskData = taskData.find(
        (task) => task.taskName === selectedTask
      );
      const taskId = selectedTaskData ? selectedTaskData.taskId : "";

      const selectedUserData = userData.find(
        (user) => user.username === selectedUser
      );
      const userId = selectedUserData ? selectedUserData.userId : "";

      const token = getCookie("Authorization");

      const response = await fetch(`${apiUrl}/admin/task-user`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: data.id,
          taskId: taskId,
          userId: userId,
        }),
      });

      if (!response.ok) {
        messageFunc("error", "The task option you entered is available.");
      }

      const responseData = await response.json();

      if (responseData.status === 200) {
        navigate("/adminpanel/taskUser");
      } else {
        messageFunc("error", "Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleTaskChange = (value) => {
    setSelectedTask(value);
  };

  const handleUserChange = (value) => {
    setSelectedUser(value);
  };

  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/task-user/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc("error", "Network response was not ok");
        }
        return res.json();
      })
      .then((datax) => {
        const taskuser = datax.response.data;
        setTaskUser(taskuser);
        setSelectedTask(taskuser.task.name);
        setSelectedUser(taskuser.username);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const fetchTaskUserData = async () => {
      try {
        const token = getCookie("Authorization");
        const userResponse = await fetch(`${apiUrl}/admin/user`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token,
          },
        });
        if (!userResponse.ok) {
          messageFunc("error", "Network response was not ok");
        }
        const userData = await userResponse.json();
        setUserData(
          userData.response.data.map((user) => ({
            userId: user.id,
            username: user.username,
          }))
        );
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const fetchTaskData = async () => {
      try {
        const token = getCookie("Authorization");
        const taskResponse = await fetch(`${apiUrl}/admin/task`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token,
          },
        });
        if (!taskResponse.ok) {
          messageFunc("error", "Network response was not ok");
        }
        const taskData = await taskResponse.json();
        settaskData(
          taskData.response.data.map((task) => ({
            taskId: task.id,
            taskName: task.name,
          }))
        );
      } catch (error) {
        console.error("Error fetching task data:", error);
      }
    };

    Promise.all([fetchTaskUserData(), fetchTaskData()]).catch((error) => {
      console.error("Error fetching task and user data:", error);
    });
  }, []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              // items={sidebarItems}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>TaskUser Edit</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  onClick={handleSave}
                  style={{ fontSize: "1.4rem" }}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form scroll={{ x: 900 }} className="sub-layout">
                <Select
                  style={{ width: "100%", marginBottom: "20px" }}
                  value={selectedTask}
                  onChange={handleTaskChange}
                >
                  {taskData.map((task) => (
                    <Option key={task.taskId} value={task.taskName}>
                      {task.taskName}
                    </Option>
                  ))}
                </Select>
                <Select
                  style={{ width: "100%", marginBottom: "20px" }}
                  value={selectedUser}
                  onChange={handleUserChange}
                >
                  {userData.map((user) => (
                    <Option key={user.userId} value={user.username}>
                      {user.username}
                    </Option>
                  ))}
                </Select>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default TaskEdit;
