import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from './pages/Main'
import ReadMore from './pages/ReadMore'
import Profile from './pages/Profile'
import Login from './pages/Login'
import Register from './pages/Register'
import ChangePassword from './pages/ChangePassword'
import About from './components/admin/About'
import AboutEdit from './components/admin/AboutEdit'
import Services from './components/admin/Services'
import ServicesEdit from './components/admin/ServicesEdit'
import ServicesAdd from './components/admin/ServicesAdd'
import InternProject from './components/admin/InternProject'
import CompanyProject from './components/admin/CompanyProject'
import InternEdit from './components/admin/InternEdit'
import CompanyEdit from './components/admin/CompanyEdit'
import CompanyAdd from './components/admin/CompanyAdd'
import InternAdd from './components/admin/InternAdd'
import Education from './components/admin/Education'
import EducationEdit from './components/admin/EducationEdit'
import EducationAdd from './components/admin/EducationAdd'
import Readmore from './components/admin/Readmore'
import ReadmoreAdd from './components/admin/ReadmoreAdd'
import ReadmoreEdit from './components/admin/ReadmoreEdit'
import User from './components/admin/User'
import Category from './components/admin/Category'
import CategoryAdd from './components/admin/CategoryAdd'
import CategoryEdit from './components/admin/CategoryEdit'
import UserEdit from './components/admin/UserEdit'
import Task from './components/admin/Task'
import TaskEdit from './components/admin/TaskEdit'
import TaskAdd from './components/admin/TaskAdd'
import ProjectEdit from './components/admin/ProjectEdit'
import Project from './components/admin/Project'
import ProjectAdd from './components/admin/ProjectAdd'
import OTP from './components/OTP'
import Projects from './pages/Projects'
import Tasks from './pages/Tasks'
import TaskUser from './components/admin/TaskUser'
import TaskUserAdd from './components/admin/TaskUserAdd'
import TaskUserEdit from './components/admin/TaskUserEdit'
import UserProject from './components/admin/UserProject'
import UserTask from './components/admin/UserTask'
import ForgetPassword from './pages/ForgetPassword'
import ResetPassword from './pages/ResetPassword'
import Email from './components/admin/Email';
import EmailDetails from './components/admin/EmailDetails';
import TaskDetails from './pages/TaskDetails';
import ProfileProjectDetails from './pages/ProfileProjectDetails';
import ProjectDetails from './components/admin/ProjectDetails';
import { getCookie } from './functions/token';
import AuthLayout from './pages/PrivateRoute';


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const token = getCookie('Authorization');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' isLoggedIn={isLoggedIn} element={<Main isLoggedIn={true} />}></Route>
        <Route path='/login' element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        <Route path='/register' element={<Register />}></Route>
        <Route path='/otp-code-register' element={<OTP />}></Route>
        <Route path='/site/it-info-category/:id' element={<ReadMore />}></Route>
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/profileprojectdetails/:id" element={<ProfileProjectDetails />} />
        <Route element={<AuthLayout />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path='/taskdetails/:id' element={<TaskDetails />}></Route>
          <Route path='/changepassword' element={<ChangePassword />}></Route>
          <Route path='/adminpanel/about' element={<About />}></Route>
          <Route path='/adminpanel/aboutedit' element={<AboutEdit />}></Route>
          <Route path='/adminpanel/services' element={<Services />}></Route>
          <Route path='/adminpanel/servicesedit/:id' element={<ServicesEdit />}></Route>
          <Route path='/adminpanel/servicesadd' element={<ServicesAdd />}></Route>
          <Route path='/adminpanel/readmore' element={<Readmore />}></Route>
          <Route path='/adminpanel/readmoreedit/:id' element={<ReadmoreEdit />}></Route>
          <Route path='/adminpanel/readmoreadd' element={<ReadmoreAdd />}></Route>
          <Route path='/adminpanel/companyproject' element={<CompanyProject />}></Route>
          <Route path='/adminpanel/companyedit/:id' element={<CompanyEdit />}></Route>
          <Route path='/adminpanel/companyadd' element={<CompanyAdd />}></Route>
          <Route path='/adminpanel/internproject' element={<InternProject />}></Route>
          <Route path='/adminpanel/internedit/:id' element={<InternEdit />}></Route>
          <Route path='/adminpanel/internadd' element={<InternAdd />}></Route>
          <Route path='/adminpanel/education' element={<Education />}></Route>
          <Route path='/adminpanel/educationadd' element={<EducationAdd />}></Route>
          <Route path='/adminpanel/educationedit/:id' element={<EducationEdit />}></Route>
          <Route path='/adminpanel/user' element={<User />}></Route>
          <Route path='/adminpanel/useredit/:id' element={<UserEdit />}></Route>
          <Route path='/adminpanel/category' element={<Category />}></Route>
          <Route path='/adminpanel/categoryadd' element={<CategoryAdd />}></Route>
          <Route path='/adminpanel/categoryedit/:id' element={<CategoryEdit />}></Route>
          <Route path='/adminpanel/task' element={<Task />}></Route>
          <Route path='/adminpanel/taskEdit/:id' element={<TaskEdit />}></Route>
          <Route path='/adminpanel/taskAdd' element={<TaskAdd />}></Route>
          <Route path='/adminpanel/project' element={<Project />}></Route>
          <Route path='/adminpanel/projectdetails/:id' element={<ProjectDetails />}></Route>
          <Route path='/projectEdit/:id' element={<ProjectEdit />}></Route>
          <Route path='/projectAdd' element={<ProjectAdd />}></Route>
          <Route path='/adminpanel/taskUser' element={<TaskUser />}></Route>
          <Route path='/adminpanel/taskUserAdd' element={<TaskUserAdd />}></Route>
          <Route path='/adminpanel/taskUserEdit/:id' element={<TaskUserEdit />}></Route>
          <Route path='/adminpanel/userproject/:id' element={<UserProject />}></Route>
          <Route path='/adminpanel/usertask/:id' element={<UserTask />}></Route>
          <Route path='/adminpanel/email' element={<Email />}></Route>
          <Route path='/adminpanel/emailDetails/:id' element={<EmailDetails />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
