import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Form,
  Input,
  Layout,
  Menu,
  Select,
  message,
  theme,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import TextArea from "antd/es/input/TextArea";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;
const { Option } = Select;

const TaskEdit = () => {
  const { id } = useParams();
  const [taskData, setTaskData] = useState({
    id: id,
    file: "",
    name: "",
    description: "",
    categoryId: "",
    categoryName: "",
  });
  const [categories, setCategories] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const token = getCookie("Authorization");
      try {
        const response = await fetch(`${apiUrl}/profile/category`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${token}`,
          },
        });
        const data = await response.json();
        setCategories(data.response.data);
        if (!taskData.categoryId && data.response.data.length > 0) {
          setTaskData((prevData) => ({
            ...prevData,
           categoryId: taskData.categoryId ? taskData.categoryId : selectedCategory
          }));
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);


  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/profile/task/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((taskData) => {
        const task = taskData.response.data;
        setTaskData(task);
        setSelectedCategory(task.categoryId);
      })
      .catch((error) => {
        console.error("Error fetching task data:", error);
      });
  }, [id]);

  const handleCategoryChange = (value) => {
    setTaskData({
      ...taskData,
      categoryId: value,
    });
    setSelectedCategory(value);
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaskData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateInputs = () => {
    const errors = {};

    if (!taskData.name) {
      errors.name = "Please enter a Name";
    }

    if (!taskData.description) {
      errors.description = "Please enter a Description";
    }

    return errors;
  };

  const handleSave = async () => {
    try {
      const validationErrors = validateInputs();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const token = getCookie("Authorization");
        const uploadResponse = await fetch(`${apiUrl}/upload`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: formData,
        });
        if (!uploadResponse.ok) {
          messageFunc("error", "Failed to upload file");
          return;
        }
        const uploadData = await uploadResponse.json();
        const uploadedFile = uploadData.response.data;
        taskData.file = uploadedFile;
        console.log("uploadedFile", uploadedFile);

      }

      const token = getCookie("Authorization");
      const response = await fetch(`${apiUrl}/admin/task`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: id,
          file: taskData.file,
          name: taskData.name,
          description: taskData.description,
          categoryId: taskData.categoryId,
        }),
      });

      const responseData = await response.json();
      setTaskData({
        id: responseData.id,
        file: responseData.file,
        name: responseData.name,
        description: responseData.description,
        categoryId:responseData.categoryId,
      });
      navigate("/adminpanel/task");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleItemClick = (href) => {
    navigate(href);
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Task Edit</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  onClick={handleSave}
                  style={{ fontSize: "1.4rem" }}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form scroll={{ x: 800 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    placeholder="Name"
                    name="name"
                    value={taskData.name}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.description ? "error" : ""}
                  help={errors.description}
                >
                  <TextArea
                    placeholder="Description"
                    name="description"
                    style={{ minHeight: "150px" }}
                    value={taskData.description}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item> 
                  <Select
                    placeholder="Select a category"
                    onChange={handleCategoryChange}
                    mode="default"
                    value={taskData.categoryId}
                  >
                    {categories.map((category) => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="File"
                  validateStatus={errors.file ? "error" : ""}
                  help={errors.file}                
                >
                  <input type="file" id="file" onChange={handleFileChange} />
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default TaskEdit;
