import React, { useEffect, useState } from "react";
import { Flex, Space, Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { Footer } from "antd/es/layout/layout";
import apiUrl from "../../api/axios";
import AdminHeader from "./AdminHeader";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const { Column } = Table;

const About = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setTableHeight(window.innerHeight);
    });
  }, []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`${apiUrl}/site/about-us`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        const data = response.response.data;
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const formattedData = {
    icon: data.icon,
    companyName: data.companyName,
    info: data.info,
    phone: data.phone,
    email: data.email,
    locationText: data.locationText,
    locationLink: data.locationLink,
    pageInfo: data.pageInfo,
  };

  return (
    <Layout className="main-layout">
      <AdminHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
            display: sidebarOpen ? "block" : "none",
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ borderRight: 0 }}
            items={sidebarItems.map((item, index) => ({
              ...item,
              onClick: () => handleItemClick(item.href),
            }))}
          />
        </Sider>
        <Layout style={{ padding: "0 2rem 1rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBlock: "1rem",
            }}
          >
            <p style={{ fontSize: "1.6rem" }}>About</p>
            <Flex justify="flex-end" align="center"></Flex>
          </div>
          <Content
            style={{
              margin: 0,
              minHeight: 300,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              overflowY: "auto",
            }}
          >
            <Table
              dataSource={[formattedData]}
              bordered
              pagination={false}
              scroll={{ y: tableHeight - 250, x: 1000 }}
            >
              <Column
                title="Logo"
                dataIndex="icon"
                key="icon"
                width={170}
                render={(text, record) => {
                  return (
                    <div className="img-div">
                      <img
                        onClick={() =>
                          window.open(
                            `${apiUrl}/uploads/${record.icon}`,
                            "_blank"
                          )
                        }
                        src={(data && data.icon) ?
                          (data.icon.startsWith('http://') || data.icon.startsWith('https://')) ?
                            data.icon : `${apiUrl}/uploads/${data.icon}` :
                          data.icon}
                        style={{ height: "50px", cursor: "pointer" }}
                        alt=""
                      />
                    </div>
                  );
                }}
              />

              <Column
                title="Company Name"
                dataIndex="companyName"
                key="companyName"
                width={100}
              />
              <Column title="Info" dataIndex="info" key="info" width={90} ellipsis={{ showTitle: true }}/>
              <Column title="Phone" dataIndex="phone" key="phone" width={90} ellipsis={{ showTitle: true }} />
              <Column title="Email" dataIndex="email" key="email" width={90}ellipsis={{ showTitle: true }} />

              <Column
                title="Location Text"
                dataIndex="locationText"
                key="locationText"
                width={100}
                ellipsis={{ showTitle: true }}
              />
              <Column
                title="Location Link"
                dataIndex="locationLink"
                key="locationLink"
                width={100}
                ellipsis={{ showTitle: true }}
              />
              <Column
                title="Page Info"
                dataIndex="pageInfo"
                key="pageInfo"
                width={"100px"}
                ellipsis={{ showTitle: true }}
              />
              <Column
                title="Action"
                key="action"
                width={65}
                render={(_, record) => (
                  <Space size="middle">
                    <Button
                      style={{ color: "blue" }}
                      href="/adminpanel/aboutedit"
                    >
                      <EditOutlined />
                    </Button>
                  </Space>
                )}
              />
            </Table>
          </Content>
          <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
            Created by NSP SOLUTIONS ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default About;
