import { Layout, Menu, theme } from "antd";
import React, { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import Sider from "antd/es/layout/Sider";
import { useNavigate, useParams } from "react-router-dom";
import { Content, Footer } from "antd/es/layout/layout";
import apiUrl from "../../api/axios";
import { getCookie } from "../../functions/token";
import { sidebarItems } from "../../functions/sidebar";

const EmailDetails = () => {
  const { id } = useParams();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [data, setData] = useState([]);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setTableHeight(window.innerHeight);
    });
  }, []);

  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/email/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const data = response.response.data;
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <Layout className="main-layout">
      <AdminHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
            display: sidebarOpen ? "block" : "none",
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ borderRight: 0 }}
            // items={sidebarItems}
            items={sidebarItems.map((item) => ({
              ...item,
              onClick: () => handleItemClick(item.href),
            }))}
          />
        </Sider>
        <Layout style={{ padding: "0 2rem 1rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBlock: "1rem",
            }}
          >
            <p style={{ fontSize: "1.6rem" }}>Email Details</p>
          </div>
          <Content
            style={{
              margin: 0,
              padding: "6rem 10rem",
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              overflowY: "auto",
            }}
          >
            <div scroll={{ y: tableHeight - 250, x: 800 }}>
              <h3>
                <a
                  href={`mailto:${data.email}`}
                  style={{ color: "#1677FF90 " }}
                >
                  {data.email}
                </a>
              </h3>
              <p style={{ color: "#5E5E5E", paddingBottom: "2rem" }}>
                {data.date}
              </p>
              <h2 style={{ paddingBottom: ".6rem" }}>{data.subject}</h2>
              <p>{data.message}</p>
            </div>
          </Content>
          <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
            Created by NSP SOLUTIONS ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default EmailDetails;
