import { jwtDecode } from 'jwt-decode';


const setToken = (accessToken) => {
    const expirationTime = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `accessToken=${accessToken}; expires=${expirationTime}; path=/`;
};

const getToken = () => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    const accessTokenCookie = cookies.find(cookie => cookie.startsWith('accessToken='));
    if (accessTokenCookie) {
        const accessToken = accessTokenCookie.split('=')[1];
        return accessToken || null;
    } else {
        return null;
    }
};

function getCookie(name) {
    const cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        const cookie = cookieArray[i].trim();
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
        }
    }
    return null;
}

const saveToken = (token) => {
    setToken(token);
};


const checkTokenExpiration = () => {
    const token = getToken();
    if (token === null) {
        window.location.href = "/login";
    }
};

const clearCookies = () => {
    document.cookie.split(";").forEach(cookie => {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    });
};

const clearToken = () => {
    try {
        clearCookies();
        document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        setToken(null);
    } catch (error) {
        console.error(error);
    }
};


const apiTokenString = getToken();


const getUserIdFromToken = () => {
    const token = getToken();
    if (token === null) {
        return null;
    }

    try {
        const decodedToken = jwtDecode(token);
        return decodedToken.userId;
    } catch (error) {
        console.error('Token decode edilemedi veya userId alınamadı.');
        return null;
    }
};



export { saveToken, getToken, checkTokenExpiration, clearToken, getCookie, getUserIdFromToken, apiTokenString };

