import React, { useState } from "react";
import { Button, Flex, Form, Input, Layout, Menu, message, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const EducationAdd = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const [course, setEducation] = useState([]);
  const [name, setName] = useState("");
  const [item1, setItem1] = useState("");
  const [item2, setItem2] = useState("");
  const [item3, setItem3] = useState("");
  const [time, setTime] = useState("");
  const [cost, setCost] = useState("");
  const [errors, setErrors] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const validateInputs = () => {
    const errors = {};

    if (!name) {
      errors.name = "Please fill in Name";
    }
    if (!time) {
      errors.time = "Please fill in Time";
    }
    if (!item1) {
      errors.item1 = "Please fill in Item1";
    }
    if (!item2) {
      errors.item2 = "Please fill in Item2";
    }
    if (!item3) {
      errors.item3 = "Please fill in Item3";
    }
    if (!cost) {
      errors.cost = "Please fill in Cost";
    }

    return errors;
  };
  const addCourse = () => {
    const errors = validateInputs();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/course`, {
      method: "POST",
      body: JSON.stringify({
        name,
        cost,
        time,
        item1,
        item2,
        item3,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          res.json();
        } else {
          messageFunc("error", "Network response was not ok");
        }
      })
      .then((data) => {
        setEducation([...course, data]);
        setName("");
        setCost("");
        setTime("");
        setItem1("");
        setItem2("");
        setItem3("");
        navigate("/adminpanel/education");
      });
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              // items={sidebarItems}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Education Add</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  onClick={addCourse}
                  style={{ fontSize: "1.4rem" }}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form scroll={{ x: 400 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    placeholder="Name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  validateStatus={errors.time ? "error" : ""}
                  help={errors.time}
                >
                  <Input
                    placeholder="Time"
                    name="time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  validateStatus={errors.cost ? "error" : ""}
                  help={errors.cost}
                >
                  <Input
                    placeholder="Cost"
                    name="cost"
                    value={cost}
                    onChange={(e) => setCost(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  validateStatus={errors.item1 ? "error" : ""}
                  help={errors.item1}
                >
                  <Input
                    placeholder="Item 1"
                    name="item1"
                    value={item1}
                    onChange={(e) => setItem1(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  validateStatus={errors.item2 ? "error" : ""}
                  help={errors.item2}
                >
                  <Input
                    placeholder="Item 2"
                    name="item2"
                    value={item2}
                    onChange={(e) => setItem2(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  validateStatus={errors.item3 ? "error" : ""}
                  help={errors.item3}
                >
                  <Input
                    placeholder="Item 3"
                    name="item3"
                    value={item3}
                    onChange={(e) => setItem3(e.target.value)}
                  />
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default EducationAdd;
