import React, { useEffect, useState } from "react";
import {
  LinkedinOutlined,
  GithubOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Select,
  Layout,
  Menu,
  theme,
  Flex,
  DatePicker,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import apiUrl from "../api/axios";
import { getCookie } from "../functions/token";
import MenuHeader from "./MenuHeader";
import { Footer } from "antd/es/layout/layout";
import moment from "moment";
import { sidebarItem } from "../functions/sidebar";

const { Option } = Select;
const { Content, Sider } = Layout;

const Profile = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: "",
    surname: "",
    phone: "",
    birthday: "",
    github: "",
    linkedin: "",
    cv: "",
    image: "",
    categoryId: "",
    state: "",
  });
  const [categories, setCategories] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleProfileClick = () => {
    setSidebarOpen(!sidebarOpen);
  };
  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/profile/info`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((userData) => {
        const user = userData.response.data;
        setUserData({
          name: user.name,
          surname: user.surname,
          phone: user.phone,
          birthday: user.birthday,
          github: user.github,
          linkedin: user.linkedin,
          cv: user.cv,
          image: user.image,
          categoryId: user.category ? user.category.id : null,
          state: user.state,
        });
        navigate("/profile");
      })
      .catch((error) => {
        console.log("Error fetching user data:", error);
      });

    fetch(`${apiUrl}/profile/category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((categoryData) => {
        const categories = categoryData.response.data;
        setCategories(categories);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const handleCategoryChange = (value) => {
    setUserData((prevData) => ({
      ...prevData,
      categoryId: value,
    }));
  };

  const onFinish = (value) => {
    console.log(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    setUserData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };

  const [imageFile, setImageFile] = useState(null);
  const [cvFile, setCvFile] = useState(null);

  const handleImageFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleCvFileChange = (e) => {
    const file = e.target.files[0];
    if (file && isFileAllowed(file)) {
      setCvFile(file);
    } else {
      console.error(
        "Invalid file type for CV. Please select a valid image or PDF file."
      );
    }
  };

  const isFileAllowed = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    return allowedTypes.includes(file.type);
  };

  const handleDeleteFile = async (fileType) => {
    try {
      const token = getCookie("Authorization");

      const response = await fetch(`${apiUrl}/delete/${fileType}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });

      setUserData((prevUserData) => ({
        ...prevUserData,
        [fileType]: null,
      }));
    } catch (error) {
      console.error(`Error deleting ${fileType}:`, error);
    }
  };

  const [errors, setErrors] = useState({});
  const validateInputs = () => {
    const errors = {};

    if (!userData.name) {
      errors.name = "Please enter a name";
    }
    if (!userData.surname) {
      errors.surname = "Please enter a surname";
    }
    if (!userData.cv && !cvFile) {
      errors.cv = "Please enter a cv";
    }

    if (!userData.categoryId || userData.categoryId === "Select a category") {
      errors.categoryId = "Please select a category";
    }
    return errors;
  };

  const handleSave = async () => {
    try {
      let updatedPhone = userData.phone;

      const errors = validateInputs();
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      if (userData.phone) {
        if (!updatedPhone.trim().startsWith("+994")) {
          updatedPhone = "+994" + updatedPhone.trim();
        }
        if (userData.phone.trim().startsWith("+994")) {
          updatedPhone = updatedPhone.substring(4);
        }
      }

      if (imageFile) {
        const formData = new FormData();
        formData.append("file", imageFile);
        const token = getCookie("Authorization");
        const uploadResponse = await fetch(`${apiUrl}/upload`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: formData,
        });
        if (!uploadResponse.ok) {
          messageFunc("error", "Failed to upload file");
        }
        const uploadData = await uploadResponse.json();
        const uploadedFile = uploadData.response.data;
        userData.image = uploadedFile;
      }

      if (cvFile) {
        const cvFormData = new FormData();
        cvFormData.append("file", cvFile);
        const token = getCookie("Authorization");
        const cvUploadResponse = await fetch(`${apiUrl}/upload`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: cvFormData,
        });
        if (!cvUploadResponse.ok) {
          messageFunc("error", "Failed to upload CV");
        }
        const cvUploadData = await cvUploadResponse.json();
        const uploadedCV = cvUploadData.response.data;
        userData.cv = uploadedCV;
      }
      const token = getCookie("Authorization");
      const response = await fetch(`${apiUrl}/profile/info`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({ ...userData, phone: updatedPhone }),
      });

      const responseData = await response.json();
      setUserData((prevUserData) => ({
        ...prevUserData,
        ...responseData.response.data,
      }));
      setTimeout(() => {
        window.location.reload();
      }, 1);
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };


  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const handleIconClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <MenuHeader onProfileClick={handleProfileClick} />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
            >
              {sidebarItem.map((item) => (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  onClick={() => handleItemClick(item.href)}
                >
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Profile</p>
              <Flex justify="flex-end" align="center">
                <Button type="primary" onClick={handleSave}>
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form onFinish={onFinish}>
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    name="name"
                    placeholder="Name"
                    value={userData.name ? userData.name : ""}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.surname ? "error" : ""}
                  help={errors.surname}
                >
                  <Input
                    placeholder="Surname"
                    name="surname"
                    value={userData.surname ? userData.surname : ""}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item
                >
                  <Input
                    name="phone"
                    onChange={handleInputChange}
                    value={
                      userData.phone && userData.phone.trim().startsWith("+994")
                        ? userData.phone.substring(4)
                        : userData.phone
                    }
                    addonBefore="+994"
                    placeholder="(50)000-00-00"
                  />
                </Form.Item>
                <Form.Item
                >
                  <Input
                    name="github"
                    onChange={handleInputChange}
                    value={userData.github ? userData.github : ""}
                    placeholder="Github link"
                    suffix={
                      <GithubOutlined
                        onClick={() => {
                          if (userData.github) {
                            handleIconClick(userData.github);
                          }
                        }}
                        style={{ cursor: userData.github ? "pointer" : "not-allowed", color: userData.github ? "inherit" : "gray" }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item
                >
                  <Input
                    name="linkedin"
                    onChange={handleInputChange}
                    value={userData.linkedin ? userData.linkedin : ""}
                    placeholder="Linkedin link"
                    suffix={
                      <LinkedinOutlined
                        onClick={() => {
                          if (userData.linkedin) {
                            handleIconClick(userData.linkedin);
                          }
                        }}
                        style={{ cursor: userData.linkedin ? "pointer" : "not-allowed", color: userData.linkedin ? "inherit" : "gray" }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.categoryId ? "error" : ""}
                  help={errors.categoryId}>
                  <Select
                    placeholder="Select a category"
                    onChange={handleCategoryChange}
                    value={userData.categoryId ? userData.categoryId : "Select a category"}
                    mode="default"
                    required
                  >
                    {categories
                      ? categories.map((category) => (
                        <Option key={category.id} value={category.id}>
                          {category.name}
                        </Option>
                      ))
                      : ""}
                  </Select>
                </Form.Item>
                <Form.Item
                  validateStatus={errors.birthday ? "error" : ""}
                  help={errors.birthday}
                >
                  <DatePicker
                    name="birthday"
                    style={{ width: "100%" }}
                    onChange={(date, dateString) =>
                      handleInputChange({
                        target: { name: "birthday", value: dateString },
                      })
                    }
                    value={userData.birthday ? moment(userData.birthday) : ""}
                    format="YYYY-MM-DD"
                    placeholder="Birthday(YYYY-MM-DD)"
                  />
                </Form.Item>
                <Flex horizontal="true">
                  <div className="cv-container">
                    {userData.image && (
                      <>
                        <a
                          href={`${apiUrl}/uploads/${userData.image}`}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "underline",
                            marginBottom: "2rem",
                          }}
                        >
                          Download current profile image↗
                        </a>
                        <DeleteOutlined style={{marginLeft: 15}} onClick={() => handleDeleteFile('image')}/>
                      </>
                    )}
                    <Form.Item label="Image" valuePropName="file">
                      <input type="file" onChange={handleImageFileChange} accept="image/*" />
                    </Form.Item>
                  </div>
                  <div className="cv-container">
                    {userData.cv && (
                      <>
                      <a
                        href={`${apiUrl}/uploads/${userData.cv}`}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "underline",
                          marginBottom: "2rem",
                        }}
                      >
                        Download current CV↗
                      </a>
                      <DeleteOutlined style={{marginLeft: 15}} onClick={() => handleDeleteFile('cv')}/>
                      </>
                    )}
                    <Form.Item label="CV" valuePropName="file"
                      validateStatus={errors.cv ? "error" : ""}
                      help={errors.cv}
                    >
                      <input
                        type="file"
                        name="fileName"
                        onChange={handleCvFileChange}
                      />
                    </Form.Item>
                  </div>
                </Flex>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default Profile;
