import React, { useEffect, useRef, useState } from "react";
import { Flex, Modal, Space, Table, message } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import apiUrl from "../../api/axios";
import { getCookie } from "../../functions/token";
import { Footer } from "antd/es/layout/layout";
import AdminHeader from "./AdminHeader";
import { sidebarItems } from "../../functions/sidebar";
import { getColumnSearchProps } from "../../functions/search";
const { Content, Sider } = Layout;

const TaskUser = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/task-user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc("error", "Network response was not ok");
        }
        return res.json();
      })
      .then((datax) => {
        setUserData(Object.values(datax.response.data));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleDelete = (id) => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/task-user/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        if(resp.status === 500) {
          messageFunc("error", "Task can't be deleted because it has been merged with a project or user");
        }else{setUserData(userData.filter((resp) => resp.id !== id));}
        // navigate(`/adminpanel/taskUser`);
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const showModal = (id) => {
    setIsModalVisible(true);
    setDeleteItemId(id);
  };

  const handleOk = () => {
    handleDelete(deleteItemId);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const expandedRowRender = (record) => {
    const tasks = record.task;
    const columns = [
      {
        title: "Task description",
        dataIndex: "description",
        key: "description",
        ellipsis: { showTitle: true },
      },
      {
        title: "Task file",
        dataIndex: "file",
        key: "file",
        render: (fileSrc) => (
          <a href={`${apiUrl}/uploads/${fileSrc}`} target="_blank" rel="noreferrer" download style={{textDecoration:"underline"}}>Download file↗</a>
        ),
      },
    ];
    return <Table columns={columns} dataSource={[tasks]} pagination={false} />;
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      width: 60,
      render: (_, record, index) => index + 1,
      className: "center-align",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ...getColumnSearchProps("username", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)
    },
    {
      title: "Task Name",
      dataIndex: ["task", "name"],
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{ color: "blue" }}
            href={`/adminpanel/taskUserEdit/${record.id}`}
          >
            <EditOutlined />
          </Button>
          <div>
            <Button
              key="buton"
              danger
              style={{ color: "red" }}
              onClick={() => {
                showModal(record.id);
              }}
            >
              <DeleteOutlined />
            </Button>
            <Modal
              key={`modal-${record.id}`}
              title="Delete"
              open={isModalVisible  && deleteItemId === record.id}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <p>Are you sure you want to delete?</p>
            </Modal>
          </div>{" "}
        </Space>
      ),
    },
  ];

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setTableHeight(window.innerHeight);
    });
  }, []);

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Task User</p>
              <Flex justify="flex-end" align="center">
                <Button
                  style={{ fontSize: "1.4rem", marginRight: "1.5rem" }}
                  href="/adminPanel/taskUserAdd"
                >
                  Add
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Table
                columns={columns}
                dataSource={userData}
                expandable={{
                  expandedRowRender: (record) => expandedRowRender(record),
                  defaultExpandedRowKeys: ["0"],
                }}
                bordered
                pagination={false}
                scroll={{ y: tableHeight - 250, x: 900 }}
                rowKey={(record) => record.id}
              />
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default TaskUser;
