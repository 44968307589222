import React, { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Breadcrumb, Card, Flex, Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../functions/token";
import apiUrl from "../api/axios";
import Meta from "antd/es/card/Meta";
import MenuHeader from "./MenuHeader";
import img from "../img/default.png";
import { Footer } from "antd/es/layout/layout";
import { sidebarItem } from "../functions/sidebar";
const { Content, Sider } = Layout;

const Projects = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleProfileClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const [tasks, setTasks] = useState([]);

  const handleItemClick = (href) => {
    navigate(href);
  };

  useEffect(() => {
    const fetchProjectsOfUser = async () => {
      try {
        const token = getCookie("Authorization");
        const response = await fetch(`${apiUrl}/profile/task-user`, {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTasks(data.response.data);
        } else {
          return null;
        }
      } catch (error) {
        console.error("Error fetching projects of user:", error);
      }
    };

    fetchProjectsOfUser();
  }, []);

  return (
    <Layout className="main-layout">
      <MenuHeader onProfileClick={handleProfileClick} />
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
            display: sidebarOpen ? "block" : "none",
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ borderRight: 0 }}
          >
            {sidebarItem.map((item) => (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                onClick={() => handleItemClick(item.href)}
              >
                {item.label}
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Breadcrumb style={{ margin: "16px 0", fontSize: "1.6rem" }}>
            <Breadcrumb.Item>Tasks</Breadcrumb.Item>
          </Breadcrumb>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              overflowY: "auto",
            }}
            className="sub-layout"
          >
            <Flex
              value="vertical"
              gap="middle"
              scroll={{ x: 1000 }}
              wrap="wrap"
            >
              {
              tasks ? (
              tasks.map((project) => (
                <Card
                  onClick={() => navigate(`/taskdetails/${project.task.id}`)}
                  key={project.id}
                  hoverable
                  style={{ width: 300, marginLeft: 30 }}
                  cover={
                    project.task.file &&
                    !project.task.file.endsWith(".rar") &&
                    !project.task.file.endsWith(".zip") &&
                    !project.task.file.endsWith(".pdf") ? (
                      <img
                        src={`${apiUrl}/uploads/${project.task.file}`}
                        style={{ width: "100%", height: "150px" }}
                        alt=""
                      />
                    ) : (
                      <img
                        src={img}
                        style={{ width: "100%", height: "150px" }}
                        alt="Default"
                      />
                    )
                  }
                >
                  <Meta
                    title={project.task.name}
                    description={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {project.task.description}
                        </div>
                        <a href={`/taskdetails/${project.task.id}`}>
                        <InfoCircleOutlined style={{ fontSize: "18px", marginLeft: "8px" }}/>
                        </a>
                      </div>
                    }
                  />
                </Card>
              ))) : ""
              }
            </Flex>
          </Content>
          <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
            Created by NSP SOLUTIONS ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Projects;
