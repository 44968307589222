import React, { useState } from "react";
import { Button, Form, Input, Layout, Menu, theme, Flex, message } from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import TextArea from "antd/es/input/TextArea";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const CompanyAdd = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const [company, setCompany] = useState([]);
  const [name, setName] = useState("");
  const [info, setInfo] = useState("");
  const [file, setFile] = useState("");
  const [errors, setErrors] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const addCompany = async () => {
    try {
      let hasError = false;
      const errors = {};
      if (!name) {
        errors.name = "Please fill in Project Name";
        hasError = true;
      }
      if (!info) {
        errors.info = "Please fill in Description";
        hasError = true;
      }
      if (!file) {
        errors.file = "Please select an Image";
        hasError = true;
      }

      if (hasError) {
        setErrors(errors);
        return;
      }
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const token = getCookie("Authorization");
        const uploadResponse = await fetch(`${apiUrl}/upload`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: formData,
        });
        if (!uploadResponse.ok) {
          console.error("Failed to upload file");
          return;
        }
        const uploadData = await uploadResponse.json();
        const image = uploadData.response.data;
        company.image = image;
      }
      const token = getCookie("Authorization");
      const requestData = {
        name: name,
        info: info,
        file: company.image,
      };
      fetch(`${apiUrl}/admin/company-project`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(requestData),
      })
        .then((res) => res.json())
        .then((data) => {
          setCompany([...company, data]);
          setName("");
          setInfo("");
          setFile("");
          if (data.status === 201) {
            navigate("/adminpanel/companyproject");
          } else {
            messageFunc("error", "Failed to add company project", data);
          }
        });
    } catch (error) {
      console.error("Error while adding Company Project:", error);
    }
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Company Project Add</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  style={{ fontSize: "1.4rem" }}
                  onClick={addCompany}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form scroll={{ x: 400 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    placeholder="Project Name"
                    name="name"
                    onChange={(e) => {
                      setName(e.target.value);
                      setErrors({ ...errors, name: null });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.info ? "error" : ""}
                  help={errors.info}
                >
                  <TextArea
                    placeholder="Description"
                    name="info"
                    onChange={(e) => {
                      setInfo(e.target.value);
                      setErrors({ ...errors, info: null });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Image"
                  valuePropName="file"
                  validateStatus={errors.file ? "error" : ""}
                  help={errors.file}
                >
                  <input type="file" id="file" onChange={handleFileChange} accept="image/*" />
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default CompanyAdd;
