import React from 'react'
import { Link } from 'react-router-dom';
import "swiper/css";
import "swiper/css/pagination";


const CourseCard = ({ name, info, id}) => {

  const maxLength = 50;
  const truncatedInfo = info.length > maxLength ? `${info.substring(0, maxLength)}...` : info;

  return (
    <swiper-con className="swiperslide">
      <h3>{name}</h3>
      <p>{truncatedInfo}</p>
      <div className="icon-link">
        <Link to={`site/it-info-category/${id}`}>Read More</Link>
      </div>
    </swiper-con>
  )
}


export default CourseCard