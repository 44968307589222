import React, { useEffect, useState } from "react";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  LockOutlined,
} from "@ant-design/icons";
import {
  Button,
  Flex,
  Form,
  Input,
  Layout,
  Menu,
  theme,
  Select,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import MenuHeader from "../../pages/MenuHeader";
import { Footer } from "antd/es/layout/layout";
import TextArea from "antd/es/input/TextArea";
const { Content, Sider } = Layout;
const { Option } = Select;

const sidebarItems = [
  {
    key: "sub1",
    icon: <UserOutlined />,
    label: "Profile",
    href: "/profile",
  },
  {
    key: "sub2",
    icon: <LaptopOutlined />,
    label: "Projects",
    href: "/projects",
  },
  {
    key: "sub3",
    icon: <NotificationOutlined />,
    label: "Tasks",
    href: "/tasks",
  },
  {
    key: "sub4",
    icon: <LockOutlined />,
    label: "Change Password",
    href: "/changepassword",
  },
];

const ProjectAdd = () => {
  const [project, setProject] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [file, setFile] = useState("");
  const [taskId, setTaskId] = useState("");
  const [tasks, setTasks] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [errors, setErrors] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const token = getCookie("Authorization");
        const response = await fetch(`${apiUrl}/profile/task-user`, {
          method: "GET",
          headers: {
            Authorization: token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTasks(data.response.data);
        } else {
          messageFunc("error", "Failed to fetch tasks");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchTasks();
  }, []);

  const handleTaskChange = (value) => {
    const selectedTask = tasks.find((task) => task.id === Number(value));
    if (selectedTask) {
      setSelectedTaskId(selectedTask.task.id);
    }
  };
  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
    }
  };

  const validateInputs = () => {
    const errors = {};

    if (!name) {
      errors.name = "Please enter a name";
    }

    if (!description) {
      errors.description = "Please enter a description";
    }

    if (!link) {
      errors.link = "Please enter a link";
    }

    if (!selectedTaskId) {
      errors.taskId = "Please select a task";
    }

    if (!file) {
      errors.file = "Please select a file";
    }

    return errors;
  };

  const addProject = async () => {
    try {
      const errors = validateInputs();
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      let uploadedFile = "";
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const token = getCookie("Authorization");
        const uploadResponse = await fetch(`${apiUrl}/upload`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: formData,
        });
        if (!uploadResponse.ok) {
          messageFunc("error", "Failed to upload file");
          return;
        }
        const uploadData = await uploadResponse.json();
        uploadedFile = uploadData.response.data;
        project.file = uploadedFile;
      }
      const token = getCookie("Authorization");
      const requestData = {
        name: name,
        description: description,
        file: project.file,
        link: link,
        taskId: selectedTaskId,
      };
      fetch(`${apiUrl}/profile/project`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(requestData),
      })
        .then((res) => res.json())
        .then((data) => {
          setProject([...project, data]);
          setName("");
          setDescription("");
          setFile("");
          setLink("");
          setTaskId("");
          navigate("/projects");
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <MenuHeader />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
            >
              {sidebarItems.map((item) => (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  onClick={() => handleItemClick(item.href)}
                >
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Project Add</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  onClick={addProject}
                  style={{ fontSize: "1.4rem" }}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form scroll={{ x: 900 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    placeholder="Name"
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.description ? "error" : ""}
                  help={errors.description}
                >
                  <TextArea
                    placeholder="Description"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.link ? "error" : ""}
                  help={errors.link}
                >
                  <Input
                    placeholder="Link"
                    name="link"
                    onChange={(e) => setLink(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.taskId ? "error" : ""}
                  help={errors.taskId}
                >
                  <Select
                    placeholder="Select a task"
                    onChange={handleTaskChange}
                  >
                    {tasks.map((task) => (
                      <Option key={task.id} value={task.id}>
                        {task.task.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="File"
                  valuePropName="file"
                  validateStatus={errors.file ? "error" : ""}
                  help={errors.file}
                >
                  <input type="file" id="file" onChange={handleFileChange} />
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default ProjectAdd;
