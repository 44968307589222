import React from 'react'

const ReadmoreCard = ({ name, info }) => {
  return (
    <>
      <div className="item">
        <h4>{name}</h4>
        <p>{info}</p>
      </div>
    </>

  )
}

export default ReadmoreCard
