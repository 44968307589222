import React, { useEffect, useState } from 'react'
import { Footer } from 'antd/es/layout/layout';
import apiUrl from '../api/axios';
import { Button, Form, Input, message } from 'antd';
import { getCookie } from '../functions/token';


const Contact = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [data, setData] = useState([]);
    useEffect(() => {
      fetch(`${apiUrl}/site/about-us`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          const data = response.response.data;
          setData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, []);

    const messageFunc = (typeMessage, text) => {
        messageApi.open({
        type: typeMessage,
        content: text,
        style: {
            marginTop: '15vh',
        },
        });
    };

    const onFinish = (values) => {
        const token = getCookie("Authorization");
        fetch(`${apiUrl}/site/email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `${token}`,
            },
            body: JSON.stringify(values),
        })
            .then(response => {
                if (response.ok) {
                    messageFunc("success", "Operation completed successfully!");
                    setTimeout(() => {
                        messageApi.destroy();
                    }, 2000);
                    form.resetFields();
                    return response.json();
                } else {
                    messageFunc("error", "Request failed");
                }
            })
            .then(responseData => {
            })
            .catch(error => {
                console.error('Error sending message:', error);
            })
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    return (
        <>
      {contextHolder}
            <div id='contact' className='wrapper-contact'>
                <h1>Contact</h1>
                <div className="contact">
                    <div className="map">
                        <iframe src={data.locationLink ? data.locationLink : ""} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='nspsolution'></iframe>
                        <div className="contact-info">
                            <p><i className="fa-solid fa-phone"></i> {data.phone ? data.phone : ""}</p>
                            <p><i className="fa-solid fa-envelope"></i>                    
                            <a style={{color: "black"}} href={`mailto:${data.email ? data.email : ""}`}>{data.email ? data.email : ""}</a>
                            </p>
                        </div>
                    </div>
                    <div className="form">
                        {/* {contextHolder} */}
                        <p>Get In Touch</p>
                        <h3>WE ARE HERE FOR YOU</h3>
                        <Form name="contact"
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}>

                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please enter your email address!',
                                    },
                                ]}
                            >
                                <Input placeholder='Gmail' />
                            </Form.Item>
                            <Form.Item
                                name="subject"
                                rules={[{ required: false, message: 'Please enter your subject!' }]}
                            >
                                <Input placeholder='Subject' style={{ marginTop: "5px" }} />
                            </Form.Item>

                            <Form.Item
                                name="message"
                                rules={[{ required: true, message: 'Please enter your message!' }]}
                            >
                                <Input.TextArea rows={4} placeholder='Message' style={{ marginTop: "5px" }} />
                            </Form.Item>

                            <Form.Item>
                                <Button htmlType="submit" style={{ padding: "0.2rem 2.5rem" }}>
                                    Send
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            <Footer className="footer-con" style={{ textAlign: "center", paddingBlock: "1rem", background: "#e9ebf8" }}>
                Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
            </div>
        </>
    )
}

export default Contact
