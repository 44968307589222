import React, { useState } from "react";
import { Button, Form, Input, Layout, Menu, theme, Flex, message } from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import TextArea from "antd/es/input/TextArea";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const InternAdd = () => {
  const [intern, setIntern] = useState([]);
  const [name, setName] = useState("");
  const [info, setInfo] = useState("");
  const [username, setUsername] = useState("");
  const [file, setFile] = useState("");

  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const [errors, setErrors] = useState("");

  const validateInputs = () => {
    const errors = {};

    if (!name) {
      errors.name = "Please fill in Name";
    }
    if (!info) {
      errors.info = "Please fill in Description";
    }
    if (!username) {
      errors.username = "Please fill in Username";
    }
    if (!file) {
      errors.file = "Please fill in Image";
    }

    return errors;
  };

  const addIntern = async () => {
    try {
      const errors = validateInputs();
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const token = getCookie("Authorization");
        const uploadResponse = await fetch(`${apiUrl}/upload`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: formData,
        });
        if (!uploadResponse.ok) {
          messageFunc("error", "Failed to upload file");
          return;
        }
        const uploadData = await uploadResponse.json();
        const image = uploadData.response.data;
        intern.image = image;
      }
      const token = getCookie("Authorization");
      const requestData = {
        name: name,
        info: info,
        image: intern.image,
        username: username,
      };
      fetch(`${apiUrl}/admin/intern-project`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(requestData),
      })
        .then((res) => {
          res.json();
        })
        .then((data) => {
          setIntern([...intern, data]);
          setName("");
          setInfo("");
          setUsername("");
          setFile("");
          navigate("/adminpanel/internproject");
        });
    } catch (error) {
      console.error("Error adding:", error);
    }
  };
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider width={200} style={{ background: colorBgContainer }}>
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Intern Project Add</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  onClick={addIntern}
                  style={{ fontSize: "1.4rem" }}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form scroll={{ x: 400 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    placeholder="Project Name"
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.info ? "error" : ""}
                  help={errors.info}
                >
                  <TextArea
                    placeholder="Description"
                    name="info"
                    onChange={(e) => setInfo(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.username ? "error" : ""}
                  help={errors.username}
                >
                  <Input
                    placeholder="Username"
                    name="username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Image"
                  valuePropName="file"
                  validateStatus={errors.file ? "error" : ""}
                  help={errors.file}
                >
                  <input type="file" id="file" onChange={handleFileChange} accept="image/*"/>
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default InternAdd;
