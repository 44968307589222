import React, { useEffect, useRef, useState } from "react";
import { Modal, Space, Table, message } from "antd";
import {
  DeleteOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import { Button, Input, Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import apiUrl from "../../api/axios";
import { getCookie } from "../../functions/token";
import { Footer } from "antd/es/layout/layout";
import { sidebarItems } from "../../functions/sidebar";
import AdminHeader from "./AdminHeader";
import img from "../../img/default.png";
import { getColumnSearchProps } from "../../functions/search";
const { Content, Sider } = Layout;

const Project = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [projectData, setProjectData] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };
  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/project`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc("error", "Network response was not ok");
        }
        return res.json();
      })
      .then((projectData) => {
        const project = projectData.response.data;
        setProjectData(project);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);


  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Task No",
        dataIndex: "id",
        key: "id",
        className: "center-align",
        width: 100,
        render: (_, record, index) => index + 1,
      },
      {
        title: "Task Name", dataIndex: "taskName", key: "taskName"
      },
      {
        title: "Task Description",
        dataIndex: "taskDescription",
        key: "taskDescription",
        ellipsis: { showTitle: true },
      },
      {
        title: "Task File",
        dataIndex: "taskFile",
        key: "taskFile",
        render: (fileSrc) => {
          return (
            <a
              href={`${apiUrl}/uploads/${fileSrc}`}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                marginBottom: "2rem",
                fontSize: "1.2rem"
              }}
            >
              Download task file↗
            </a>
          );
        },
      },
      {
        title: "Task Category", dataIndex: "categoryName", key: "categoryName"
      },
    ];

    console.log(record);
    return (
      <>
        <Table
          columns={columns}
          dataSource={[record]}
          pagination={false}
          rowClassName="table-row-with-border"
        />
      </>
    );
  };

  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [recordKey, setRecordKey] = useState(null);

  const handleOpenModal = (key) => {
    setRecordKey(key);
    setVisible(true);
    setInputValue("");
  };

  const handleOk = () => {
    const value = parseInt(inputValue, 10);
    if (isNaN(value) || value < 0 || value > 100) {
      messageFunc("warning", "Please enter a number between 1 and 100.");
      return;
    }
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/set-mark`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        id: recordKey,
        mark: value,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          console.error("Failed to set mark");
          return;
        }
        return response.json();
      })
      .then((data) => {
        setVisible(false);
        const updatedData = projectData.map((project) => {
          if (project.id === recordKey) {
            console.log(project.id);
            return {
              ...project,
              mark: value,
            };
          }
          return project;
        });
        setProjectData(updatedData);
      })
      .catch((error) => {
        console.error("Error setting mark:", error);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      width: 50,
      render: (_, record, index) => index + 1,
      className: "center-align",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      ...getColumnSearchProps("name", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 130,
      ...getColumnSearchProps("description", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput),
      ellipsis: { showTitle: true },
    },
    {
      title: "IsDeleted",
      dataIndex: "isDeleted",
      key: "isDeleted",
      width: 100,
      render: (isDeleted) => (isDeleted === 1 ? "true" : "false"),
      ellipsis: { showTitle: true },
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      width: 120,
      ellipsis: { showTitle: true },
      ...getColumnSearchProps("link", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput),
      render: (text, record) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}{" "}
        </a>
      )
    },
    {
      title: "Mark",
      dataIndex: "mark",
      key: "mark",
      width: 100,
      render: (text, record) => (
        <Space
          size="small"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {text}
          <Button size="small" onClick={() => handleOpenModal(record.key)}>
            Set
          </Button>
        </Space>
      ),
    },
    {
      title: "Username", dataIndex: "username", key: "username", width: 150, 
      ...getColumnSearchProps("username", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput),      
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      width: 150,
      render: (fileSrc) => {
        return (
          <a
            href={`${apiUrl}/uploads/${fileSrc}`}
            download
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "underline",
              marginBottom: "2rem",
              fontSize: "1.2rem"
            }}
          >
            Download project file↗
          </a>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      width: 120,
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{ color: "blue" }}
            title="project"
            href={`/adminpanel/projectdetails/${record.key}`}
          >
            <ProjectOutlined />
          </Button>
          <div>
            <Button
              key="buton"
              danger
              style={{ color: "red" }}
              onClick={() => {
                handleDeleteModalOpen(record.key);
              }}
            >
              <DeleteOutlined />
            </Button>
            <Modal
              key={`modal-${record.key}`}
              title="Delete"
              open={deleteModalVisible}
              onOk={handleModalDelete}
              onCancel={handleDeleteModalCancel}
            >
              <p>Are you sure you want to delete?</p>
            </Modal>
          </div>
        </Space>
      ),
    },
  ];


  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deletingRecordKey, setDeletingRecordKey] = useState(null);

  const handleDeleteModalOpen = (key) => {
    setDeletingRecordKey(key);
    setDeleteModalVisible(true);
  };

  const handleModalDelete = () => {
    handleDelete(deletingRecordKey);
    setDeleteModalVisible(false);
  };

  const handleDelete = (id) => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/project/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        setProjectData(projectData.filter((resp) => resp.id !== id));
        navigate("/adminpanel/project");
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };

  const handleDeleteModalCancel = () => {
    setDeleteModalVisible(false);
  };

  const data = projectData.map((project, index) => ({
    key: project.id,
    name: project.name,
    description: project.description,
    link: project.link,
    mark: project.mark,
    taskName: project.taskName,
    taskDescription: project.taskDescription,
    taskFile: project.taskFile,
    categoryName: project.categoryName,
    username: project.username,
    file: project.file,
    isDeleted: project.isDeleted,
  }));

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setTableHeight(window.innerHeight);
    });
  }, []);

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Project</p>
            </div>
            <Content
              style={{
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Modal
                title="Set Mark"
                open={visible}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <Input
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Please enter a number between 1 and 100."
                  type="number"
                />
              </Modal>
              <Table
                columns={columns}
                dataSource={data}
                expandable={{
                  expandedRowRender: (record) => expandedRowRender(record),
                  defaultExpandedRowKeys: ["0"],
                }}
                bordered
                pagination={false}
                scroll={{ y: tableHeight - 250, x: 800 }}
                className="sub-layout"
                rowClassName={(record) => (record.isDeleted === 1 ? "gray-row" : "")}
              />
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default Project;
