import React from "react";
import Home from "../components/Home";
import About from "../components/About";
import Education from "../components/Education";
import Contact from "../components/Contact";
import CompanyProjects from "../components/CompanyProjects";
import Navbar from "../components/Navbar";
import InternsProjects from "../components/InternsProjects";
import Courses from "../components/Courses";

const Main = ({isLoggedIn}) => {
  return (
    <>
      <Navbar  isLoggedIn={isLoggedIn}/>
      <Home />
      <About />
      <Courses />
      <Education />
      <CompanyProjects />
      <InternsProjects />
      <Contact />
    </>
  );
};

export default Main;
