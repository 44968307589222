import React, { useState, useRef, useEffect } from "react";
import man from "../img/man.png";
import OTP from "../components/OTP";
import apiUrl from "../api/axios";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import PasswordValidationBox from "./PasswordValidationBox";
import "../sass/_adminpanel.scss"
import { message } from "antd";

const Register = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmpassword] = useState("");
  const [otpCode, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordValidation, setShowPasswordValidation] = useState(false);
  const passwordInputRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();

  const validatePassword = (password) => {
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    const symbolRegex = /[@#$%]/;

    const isUppercase = uppercaseRegex.test(password);
    const isLowercase = lowercaseRegex.test(password);
    const isNumber = numberRegex.test(password);
    const isSymbol = symbolRegex.test(password);

    return isUppercase && isLowercase && isNumber && isSymbol;
  };

  const IsValidate = () => {
    let isproceed = true;
    const errors = {
      name: '',
      surname: '',
      username: '',
      password: '',
      confirmPassword: '',
      otpCode: '',
    };

    if (!name) {
      isproceed = false;
      errors.name = 'Please enter your name.';
    }

    if (!surname) {
      isproceed = false;
      errors.surname = 'Please enter your surname.';
    }

    if (!username) {
      isproceed = false;
      errors.username = 'Please enter your email.';
    } else if (!/^[\w.%+-]+@[\w.-]+\.[A-Za-z0-9]{2,}$/.test(username)) {
      isproceed = false;
      errors.username = 'Please enter a valid email.';
    }

    if (!password) {
      isproceed = false;
      errors.password = 'Please enter your password.';
    } else if (!validatePassword(password)) {
      isproceed = false;
      errors.password = 'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one symbol.';
    }

    if (!confirmPassword) {
      isproceed = false;
      errors.confirmPassword = 'Please confirm your password.';
    } else if (password !== confirmPassword) {
      isproceed = false;
      errors.confirm = 'Passwords do not match.';
    }

    setErrorMessage(errors);
    return isproceed;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (IsValidate() && !showOtp) {
      messageFunc("success", "OTP code sent to your email address. Please check your email.");
      handleRegister();
    }
  };

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const handleRegister = () => {
    const userData = { name, surname, username, password, confirmPassword, otpCode };
    fetch(`${apiUrl}/auth/register`, {
      method: "POST",
      headers: { 'content-type': 'application/json', 'accept': 'application/json' },
      body: JSON.stringify(userData)
    }).then((res) => {
      if (res.ok) {
        setShowOtp(true);
      } else {
        messageFunc("error", "Registration failed");
      }
    })
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    const handleFocus = () => {
      setShowPasswordValidation(true);
    };

    const handleBlur = () => {
      if (validatePassword(password)) {
        setShowPasswordValidation(false);
      }
    };

    const passwordInput = passwordInputRef.current;
    passwordInput.addEventListener("focus", handleFocus);
    passwordInput.addEventListener("blur", handleBlur);

    return () => {
      passwordInput.removeEventListener("focus", handleFocus);
      passwordInput.removeEventListener("blur", handleBlur);
    };
  }, [password]);

  return (
    <div className="register">
      {contextHolder}
      <div className="image">
        <img src={man} alt="man" style={{ cursor: "pointer" }} />
      </div>
      {!showOtp ? (
        <div className="form">
          <div className="form-con-register">
            <form>
              <>
                <h1>Register</h1>
                <input
                  type="text" name="name"
                  placeholder={errorMessage.name || "Name"}
                  value={name} onChange={e => setName(e.target.value)}
                  className={errorMessage.name ? 'error' : ''}
                />
                <input
                  type="text" name="surname"
                  placeholder={errorMessage.surname || "Surname"}
                  value={surname} onChange={e => setSurname(e.target.value)}
                  className={errorMessage.surname ? 'error' : ''}
                />
                <input
                  type="email" name="username"
                  placeholder={errorMessage.username || "E-mail"}
                  value={username} onChange={e => setUsername(e.target.value)}
                  className={errorMessage.username ? 'error' : ''}
                />
                <div style={{ position: "relative" }}>
                  <input
                    ref={passwordInputRef}
                    name="password" id="password-input"
                    placeholder={errorMessage.password || "Password"}
                    value={password} onChange={e => setPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    className={errorMessage.password ? 'error' : ''}
                    style={{ paddingRight: "40px", width: "100%", position: "relative" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "5%",
                      top: "50%",
                      transform: "translateY(-50%)",
                      fontSize: 15,
                      cursor: "pointer",
                    }}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                  </div>
                </div>
                <PasswordValidationBox password={password} show={showPasswordValidation} />
                <div style={{ position: "relative" }}>
                  <input
                    name="confirm"
                    placeholder={errorMessage.confirmPassword || "Confirm password"}
                    value={confirmPassword} onChange={e => setConfirmpassword(e.target.value)}
                    type={showConfirmPassword ? "text" : "password"}
                    className={errorMessage.confirmPassword ? 'error' : ''}
                    style={{ paddingRight: "40px", width: "100%" }}
                  />
                  <pre className="valid-input" style={{color: "red", fontSize: 14}}>
                    {errorMessage.confirm ? errorMessage.confirm : "  "}
                  </pre>
                  <div
                    style={{
                      position: "absolute",
                      right: "5%",
                      top: "43%",
                      transform: "translateY(-50%)",
                      fontSize: 15,
                      cursor: "pointer",
                    }}
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                  </div>
                </div>
                <button type="submit" onClick={handleSubmit}>Sign up</button>
                <p>Already have an account? <a href="/login">Sign in</a></p>
              </>
            </form>
          </div>
        </div>
      ) : (
        <OTP username={username} name={name} surname={surname} password={password} confirmPassword={confirmPassword}
          navigate={"/otp-code-register"}
        />
      )}
    </div>
  );
};

export default Register;
