import React, { useEffect, useState } from "react";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  GithubOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Layout,
  Menu,
  theme,
  Flex,
  Select,
  DatePicker,
  message,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import apiUrl from "../../api/axios";
import { getCookie } from "../../functions/token";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import moment from "moment";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;
const { Option } = Select;

const UserEdit = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState({
    id: id,
    image: "",
    name: "",
    surname: "",
    username: "",
    cv: "",
    phone: "",
    github: "",
    linkedin: "",
    categoryId: "",
    role: "",
    birthday: "",
    password: "",
    state: "",
    confirmPassword: "",
    isDeleted: "",
  });
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [imageFile, setImageFile] = useState(null);
  const [cvFile, setCvFile] = useState(null);

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = getCookie("Authorization");
        const response = await fetch(`${apiUrl}/profile/category`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${token}`,
          },
        })
          .then((res) => {
            if (!res.ok) {
              messageFunc("error", "Network response was not ok");
            }
            return res.json();
          })
          .then((data) => {
            setCategories(data.response.data);
          });
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (value) => {
    setUserData({
      ...userData,
      categoryId: value,
    });
    setSelectedCategory(value);
  };

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const token = getCookie("Authorization");
        const response = await fetch(`${apiUrl}/admin/user/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${token}`,
          },
        });
        if (!response.ok) {
          messageFunc("error", "Failed to fetch company data");
        }
        const userData = await response.json();
        const user = userData.response.data;
        console.log("xx", user);
        setUserData({
          id: id,
          name: user.name,
          role: user.role,
          state: user.state,
          surname: user.surname,
          password: "",
          image: user.image || "",
          username: user.username || "",
          cv: user.cv || "",
          phone: user.phone || "",
          github: user.github || "",
          linkedin: user.linkedin || "",
          categoryId: user.category ? user.category.id : "Select a category",
          birthday: user.birthday || "",
          isDeleted: user.isDeleted === 0 ? "True" : "False",
          confirmPassword: ""
        });
        setSelectedCategory(user.category ? user.category.id : null);
        setIsDeleted(user.isDeleted);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchCompanyData();
  }, [id]);


  const handleImageFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleCvFileChange = (e) => {
    const file = e.target.files[0];
    if (file && isFileAllowed(file)) {
      setCvFile(file);
    } else {
      messageFunc(
        "error",
        "Invalid file type for CV. Please select a valid image or PDF file."
      );
    }
  };

  const isFileAllowed = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    return allowedTypes.includes(file.type);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    setUserData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };

  const handleStateChange = (value) => {
    setUserData({
      ...userData,
      state: value,
    });
  };

  const handleRoleChange = (value) => {
    setUserData({
      ...userData,
      role: value,
    });
  };

  const [isDeleted, setIsDeleted] = useState([])

  const handleIsDeletedChange = (value) => {
    setUserData((prevData) => ({
      ...prevData,
      isDeleted: value,
    }));
    setIsDeleted(value)
  };

  const setRole = async (userId, newRole) => {
    try {
      const token = getCookie("Authorization");
      const response = await fetch(`${apiUrl}/admin/set-role`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          userId: userId,
          role: newRole,
        }),
      });
      if (!response.ok) {
        messageFunc("error", "Failed to set user role");
      }
      const responseData = await response.json();
      setUserData((prevData) => ({
        ...prevData,
        role: responseData.response.data.role,
      }));
    } catch (error) {
      console.error("Error updating user role:", error);
    }
  };

  const [errors, setErrors] = useState({});
  const validateInputs = () => {
    const errors = {};

    if (!userData.name) {
      errors.name = "Please enter a name";
    }
    if (!userData.surname) {
      errors.surname = "Please enter a surname";
    }

    if (!userData.username) {
      errors.username = "Please enter a username";
    }

    return errors;
  };

  const handleSave = async () => {
    try {
      const errors = validateInputs();
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      let updatedPhone = userData.phone;
      if (!updatedPhone.trim().startsWith("+994")) {
        updatedPhone = "+994" + updatedPhone.trim();
      }
      if (userData.phone.trim().startsWith("+994")) {
        updatedPhone = updatedPhone.substring(4);
      }
      if (imageFile) {
        const imageFormData = new FormData();
        imageFormData.append("file", imageFile);
        const token = getCookie("Authorization");
        const uploadImageResponse = await fetch(`${apiUrl}/upload`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: imageFormData,
        });
        if (!uploadImageResponse.ok) {
          messageFunc("error", "Failed to upload image");
        }
        const uploadImageData = await uploadImageResponse.json();
        const uploadedImage = uploadImageData.response.data;
        userData.image = uploadedImage;
      }

      if (cvFile) {
        const cvFormData = new FormData();
        cvFormData.append("file", cvFile);
        const token = getCookie("Authorization");
        const uploadCVResponse = await fetch(`${apiUrl}/upload`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: cvFormData,
        });
        if (!uploadCVResponse.ok) {
          messageFunc("error", "Failed to upload CV");
        }
        const uploadCVData = await uploadCVResponse.json();
        const uploadedCV = uploadCVData.response.data;
        userData.cv = uploadedCV;
      }
      userData.categoryId = selectedCategory;
      const token = getCookie("Authorization");
      const response = await fetch(`${apiUrl}/admin/edit-user`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: userData.id,
          name: userData.name,
          surname: userData.surname,
          username: userData.username,
          cv: userData.cv,
          phone: updatedPhone,
          github: userData.github,
          linkedin: userData.linkedin,
          categoryId: selectedCategory,
          role: userData.role,
          birthday: userData.birthday,
          password: userData.password,
          state: userData.state,
          image: userData.image,
          isDeleted: userData.isDeleted === "True" ? 0 : 1,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            messageFunc("error", "error");
          }
          return res.json();
        })
        .then((data) => {
          if (userData.role === "ADMIN") {
            messageFunc("error", "Admins cannot edit other admins");
          }
          if (data.status === 200) {
            userData.categoryId = selectedCategory;
            setUserData(userData.categoryId);
            userData.role = data.response.data.role;
            setRole(userData.id, userData.role);
            navigate("/adminpanel/user");
          }
        });
    } catch (error) {
      console.log("Error");
    }
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleIconClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>User Edit</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  onClick={handleSave}
                  style={{ fontSize: "1.4rem" }}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form scroll={{ x: 800 }} className="sub-layout">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Item style={{ width: "48%" }} validateStatus={errors.name ? "error" : ""}
                    help={errors.name}>
                    <Input
                      placeholder="Name"
                      name="name"
                      value={userData.name}
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                  <Form.Item style={{ width: "48%" }} validateStatus={errors.surname ? "error" : ""}
                    help={errors.surname}>
                    <Input
                      placeholder="Surname"
                      name="surname"
                      value={userData.surname}
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Item style={{ width: "48%" }}>
                    <Input
                      placeholder="Phone"
                      name="phone"
                      onChange={handleInputChange}
                      value={userData.phone && userData.phone.trim().startsWith("+994") ? userData.phone.substring(4) : userData.phone}

                      addonBefore="+994"
                    />
                  </Form.Item>
                  <Form.Item style={{ width: "48%" }}>
                    <DatePicker
                      name="birthday"
                      style={{ width: "100%" }}
                      onChange={(date, dateString) =>
                        handleInputChange({
                          target: { name: "birthday", value: dateString },
                        })
                      }
                      value={
                        userData.birthday ? moment(userData.birthday) : null
                      }
                      format="YYYY-MM-DD"
                      placeholder="Birthday(YYYY-MM-DD)"
                    />
                  </Form.Item>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "25px",
                  }}
                >
                  <div style={{ position: "relative", width: "48%" }}>
                    <Input
                      name="password"
                      placeholder="Password"
                      id="password-input"
                      onChange={handleInputChange}
                      type={showPassword ? "text" : "password"}
                      style={{ paddingRight: "40px", width: "100%" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        right: "2%",
                        top: "50%",
                        transform: "translateY(-50%)",
                        fontSize: 15,
                        cursor: "pointer",
                      }}
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )}
                    </div>
                  </div>

                  <div style={{ position: "relative", width: "48%" }}>
                    <Input
                      name="confirmPassword"
                      placeholder="Confirm password"
                      onChange={handleInputChange}
                      type={showConfirmPassword ? "text" : "password"}
                      style={{ paddingRight: "40px", width: "100%" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        right: "2%",
                        top: "50%",
                        transform: "translateY(-50%)",
                        fontSize: 15,
                        cursor: "pointer",
                      }}
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )}
                    </div>
                  </div>
                </div>
                <Form.Item>
                  <Input
                    placeholder="Github"
                    name="github"
                    value={userData.github}
                    onChange={handleInputChange}
                    suffix={
                      <GithubOutlined
                        onClick={() => handleIconClick(userData.github)}
                        style={{ cursor: "pointer" }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Input
                    placeholder="Linkedin"
                    name="linkedin"
                    value={userData.linkedin}
                    onChange={handleInputChange}
                    suffix={
                      <LinkedinOutlined
                        onClick={() => handleIconClick(userData.linkedin)}
                        style={{ cursor: "pointer" }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.username ? "error" : ""}
                  help={errors.username}>
                  <Input
                    placeholder="Username"
                    name="username"
                    value={userData.username}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Select name="role"
                    placeholder="Select a role"
                    onChange={handleRoleChange}
                    mode="default"
                    value={userData.role}>
                    <Option value="ADMIN">ADMIN</Option>
                    <Option value="USER">USER</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Select name="isDeleted"
                    placeholder="Select active"
                    onChange={handleIsDeletedChange}
                    mode="default"
                    value={isDeleted}
                  >
                    <Option value={0}>True</Option>
                    <Option value={1}>False</Option>
                  </Select>
                </Form.Item>
                <Form.Item >
                  <Select name="category"
                    placeholder="Select category"
                    onChange={handleCategoryChange}
                    mode="default"
                    value={userData.categoryId ? userData.categoryId : "Select a category"}
                  >
                    {categories.map((category) => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Select name="state"
                    placeholder="Select a state"
                    value={userData.state}
                    mode="default"
                    onChange={handleStateChange}
                  >
                    <Option value="O">O</Option>
                    <Option value="A">A</Option>
                    <Option value="B">B</Option>
                    <Option value="C">C</Option>
                    <Option value="D">D</Option>
                    <Option value="E">E</Option>
                  </Select>
                </Form.Item>
                <div style={{ display: "flex" }}>
                  <Form.Item label="Image" valuePropName="file">
                    <input type="file" onChange={handleImageFileChange} />
                  </Form.Item>
                  <Form.Item label="Cv" valuePropName="file">
                    <input type="file" onChange={handleCvFileChange} />
                  </Form.Item>
                </div>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default UserEdit;
