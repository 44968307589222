import React from 'react'
import team from "../img/businessman.png";
import project from "../img/team.png";
import customer from "../img/customer-review.png";
import achievement from "../img/achievement.png";
import { useEffect } from 'react';
import { useState } from 'react';
import apiUrl from "../api/axios";

const About = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`${apiUrl}/site/about-us`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        const data = response.response.data;
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <h1 style={{
        textAlign: "center",
        paddingBlock: "3rem",
        fontSize: "2.8rem",
        color: "#03045e"
      }}
      >About Us</h1>
      <div className="wrapper-about" id="about">
        <div className="about">
          <div className="features">
            <div className="orbit orbit1">
              <div className="circle circle1"></div>
            </div>
            <div className="orbit orbit2">
              <div className="circle circle2"></div>
            </div>
            <div className="orbit orbit3">
              <div className="circle circle3"></div>
            </div>
            <div className="cards">
              <div className="card">
                <img src={project} alt="" />
                <p>Project Completed</p>
              </div>
              <div className="card">
                <img src={customer} alt="" />
                <p>Satisfied Clients</p>
              </div>
              <div className="card card3">
                <img src={team} alt="" />
                <p>Expert Teams</p>
              </div>
              <div className="card card4">
                <img src={achievement} alt="" />
                <p>Win Awards</p>
              </div>
            </div>
          </div>
          <div className="about-con">
            <h3>{data.companyName ? data.companyName : ""}</h3>
            <p>{data.info ? data.info : ""}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
