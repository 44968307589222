import React, { useEffect, useRef, useState } from "react";
import { Flex, Modal, Space, Table, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import ColumnGroup from "antd/es/table/ColumnGroup";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import { Footer } from "antd/es/layout/layout";
import AdminHeader from "./AdminHeader";
import { getColumnSearchProps } from "../../functions/search";
import { sidebarItems } from "../../functions/sidebar";

const { Content, Sider } = Layout;
const { Column } = Table;

const Education = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/site/course`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((projectData) => {
        const project = projectData.response.data;
        setData(project);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleDelete = (id) => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/course/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc("error", "Network response was not ok");
        }
        return res.json();
      })
      .then((resp) => {
        setData((prevData) => prevData.filter((item) => item.id !== id));
        navigate("/adminpanel/education");
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const showModal = (id) => {
    setIsModalVisible(true);
    setDeleteItemId(id);
  };

  const handleOk = () => {
    handleDelete(deleteItemId);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setTableHeight(window.innerHeight);
    });
  }, []);

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Education</p>
              <Flex justify="flex-end" align="center">
                <Button
                  href="/adminPanel/educationadd"
                  style={{ fontSize: "1.4rem", marginRight: "1.5rem" }}
                >
                  Add
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Table
                dataSource={data}
                bordered
                pagination={false}
                scroll={{ y: tableHeight - 250, x: 900 }}
                className="sub-layout"
              >
                <Column
                  title="No"
                  key="index"
                  width={60}
                  className="center-align"
                  render={(text, record, index) => <span>{index + 1}</span>}
                />
                <Column
                  title="Name"
                  dataIndex="name"
                  key="name"
                  {...getColumnSearchProps(
                    "name",
                    searchText,
                    setSearchText,
                    searchedColumn,
                    setSearchedColumn,
                    searchInput
                  )}
                />
                <ColumnGroup title="Items">
                  <Column
                    title="Item1"
                    dataIndex="item1"
                    key="item1"
                    {...getColumnSearchProps(
                      "item1",
                      searchText,
                      setSearchText,
                      searchedColumn,
                      setSearchedColumn,
                      searchInput
                    )}
                  />
                  <Column
                    title="Item2"
                    dataIndex="item2"
                    key="item2"
                    {...getColumnSearchProps(
                      "item2",
                      searchText,
                      setSearchText,
                      searchedColumn,
                      setSearchedColumn,
                      searchInput
                    )}
                  />
                  <Column
                    title="Item3"
                    dataIndex="item3"
                    key="item3"
                    {...getColumnSearchProps(
                      "item3",
                      searchText,
                      setSearchText,
                      searchedColumn,
                      setSearchedColumn,
                      searchInput
                    )}
                  />
                </ColumnGroup>
                <Column
                  title="Time"
                  dataIndex="time"
                  key="time"
                  {...getColumnSearchProps(
                    "time",
                    searchText,
                    setSearchText,
                    searchedColumn,
                    setSearchedColumn,
                    searchInput
                  )}
                />
                <Column
                  title="Cost"
                  dataIndex="cost"
                  key="cost"
                  {...getColumnSearchProps(
                    "cost",
                    searchText,
                    setSearchText,
                    searchedColumn,
                    setSearchedColumn,
                    searchInput
                  )}
                />

                <Column
                  title="Action"
                  key="action"
                  width={150}
                  render={(_, record) => (
                    <Space size="middle">
                      <Button
                        style={{ color: "blue" }}
                        href={`/adminpanel/educationedit/${record.id}`}
                      >
                        <EditOutlined />
                      </Button>
                      <div>
                        <Button
                          key="buton"
                          danger
                          style={{ color: "red" }}
                          onClick={() => {
                            showModal(record.id);
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                        <Modal
                          key={`modal-${record.id}`}
                          title="Delete"
                          open={isModalVisible}
                          onOk={handleOk}
                          onCancel={handleCancel}
                        >
                          <p>Are you sure you want to delete?</p>
                        </Modal>
                      </div>
                    </Space>
                  )}
                />
              </Table>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default Education;
