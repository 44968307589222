import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import man from "../img/man.png";
import { saveToken } from "../functions/token";
import apiUrl from "../api/axios";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const Login = ({ setIsLoggedIn }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateInputs = () => {
    const errors = {};
    let isValid = true;

    if (!username || !password) {
      errors.message = 'Please enter your username or password.';
      isValid = false;

      setErrorMessage(errors);
      return isValid;
    };
  }

  const handleLogin = (e) => {
    e.preventDefault();
    const isValid = validateInputs();
    fetch(`${apiUrl}/auth/login`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          saveToken({
            accessToken: res.response.data.accessToken,
            refreshToken: res.response.data.refreshToken,
          });
          document.cookie = `Authorization=Bearer ${res.response.data.accessToken}; path=/;`;
          setIsLoggedIn(true);
          navigate(`/`);
        } else {
          setErrorMessage({ message: "Login failed. Username or password is incorrect!" });
        }
      })
      .catch((error) => {
        console.error("An error occurred. Please try again later.");
      });
  };

  return (
    <div className="login">
      <div className="image">
        <img src={man} alt="" style={{ cursor: "pointer" }} />
      </div>
      <div className="form">
        <div className="form-con">
          <h1>Login</h1>
          <pre className="valid-input" style={{ color: "red", fontSize: 14 }}>
            {errorMessage.message ? errorMessage.message : "  "} <br />
          </pre>
          <form>
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              name="username"
              placeholder="Username"
            />
            <div style={{ position: "relative" }}>
              <input
                name="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                style={{ paddingRight: "40px", width: "100%" }}
              />
              <div
                style={{
                  position: "absolute",
                  right: "5%",
                  top: "50%",
                  transform: "translateY(-50%)",
                  fontSize: 15,
                  cursor: "pointer",
                }}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </div>
            </div>
            <a href="/forget-password">Forget password?</a>
            <button type="submit" onClick={handleLogin}>
              Sign in
            </button>
          </form>
          <p>
            Don't have an account? <a href="/register">Sign up</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
