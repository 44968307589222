import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { Modal, Space, Table, message } from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Input, Layout, Menu, theme, Flex } from "antd";
import { useNavigate } from "react-router-dom";
import apiUrl from "../../api/axios";
import { getCookie } from "../../functions/token";
import { Footer } from "antd/es/layout/layout";
import img from "../../img/default.png";
import AdminHeader from "./AdminHeader";
import { sidebarItems } from "../../functions/sidebar";
import { getColumnSearchProps } from "../../functions/search";
const { Content, Sider } = Layout;

const InternProject = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [data, setData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/site/intern-project`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((projectData) => {
        const project = projectData.response.data;
        setData(project);
      })
      .catch((error) => {
        console.log("Error fetching user data:", error);
      });
  }, []);

  const handleDelete = (id) => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/intern-project/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc("error", "Network response was not ok");
        }
        return res.json();
      })
      .then((resp) => {
        setData((prevData) => prevData.filter((item) => item.id !== id));
        navigate("/adminpanel/internproject");
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const showModal = (id) => {
    setIsModalVisible(true);
    setDeleteItemId(id);
  };

  const handleOk = () => {
    handleDelete(deleteItemId);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      width: 60,
      render: (_, record, index) => index + 1,
      className: "center-align",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (fileSrc) => {
        if (!fileSrc || fileSrc.endsWith(".rar") || fileSrc.endsWith('.zip') || fileSrc.endsWith('.pdf')) {
          return (
            <img alt="" src={img} style={{height: "80px", cursor: "pointer" }} />
          );
        } else {
          return (
            <img
              alt=""
              onClick={() =>
                window.open(`${apiUrl}/uploads/${fileSrc}`, "_blank")
              }
              src={`${apiUrl}/uploads/${fileSrc}`}
              style={{height: "80px", cursor: "pointer"}}
            />
          );
        }
      },
    },
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)
    },
    {
      title: "Description",
      dataIndex: "info",
      key: "info",
      ellipsis: { showTitle: true },
      ...getColumnSearchProps("info", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ...getColumnSearchProps("username", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{ color: "blue" }}
            href={`/adminpanel/internedit/${record.id}`}
          >
            <EditOutlined />
          </Button>
          <div>
            <Button
              key="buton"
              danger
              style={{ color: "red" }}
              onClick={() => {
                showModal(record.id);
              }}
            >
              <DeleteOutlined />
            </Button>
            <Modal
              key={`modal-${record.id}`}
              title="Delete"
              open={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <p>Are you sure you want to delete?</p>
            </Modal>
          </div>
        </Space>
      ),
    },
  ];

  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setTableHeight(window.innerHeight);
    });
  }, []);

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Intern Project</p>
              <Flex justify="flex-end" align="center">
                <Button
                  style={{ fontSize: "1.4rem", marginRight: "1.5rem" }}
                  href="/adminpanel/internadd"
                >
                  Add
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Table
                columns={columns}
                dataSource={data}
                bordered
                pagination={false}
                scroll={{ y: tableHeight - 250, x: 1000 }}
                className="sub-layout"
              />
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default InternProject;
