import React from "react";
import { Button, Form, Input, Layout, Menu, Flex, theme, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import apiUrl from "../../api/axios";
import { getCookie } from "../../functions/token";
import { useEffect } from "react";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import TextArea from "antd/es/input/TextArea";
import { sidebarItems } from "../../functions/sidebar";

const { Content, Sider } = Layout;

const AboutEdit = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [tableHeight, setTableHeight] = useState(window.innerHeight);
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setTableHeight(window.innerHeight);
    });
  }, []);
  const [aboutData, setAboutData] = useState({
    icon: "",
    companyName: "",
    info: "",
    phone: "",
    email: "",
    locationText: "",
    locationLink: "",
    pageInfo: "",
  });

  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/site/about-us`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((aboutData) => {
        const about = aboutData.response.data;
        setAboutData({
          icon: about.icon,
          companyName: about.companyName,
          info: about.info,
          phone: about.phone,
          email: about.email,
          locationText: about.locationText,
          locationLink: about.locationLink,
          pageInfo: about.pageInfo,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setAboutData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [errors, setErrors] = useState("");
  const validateInputs = () => {
    const errors = {};

    if (!aboutData.companyName) {
      errors.companyName = "Please fill in Company Name";
    }
    if (!aboutData.info) {
      errors.info = "Please fill in Info";
    }
    if (!aboutData.icon) {
      errors.icon = "Please select an Icon image";
    }
    if (!aboutData.phone) {
      errors.phone = "Please fill in Phone";
    }
    if (!aboutData.email) {
      errors.email = "Please fill in Email";
    }
    if (!aboutData.locationLink) {
      errors.locationLink = "Please fill in Location Link";
    }
    if (!aboutData.locationText) {
      errors.locationText = "Please fill in Location Text";
    }
    if (!aboutData.pageInfo) {
      errors.pageInfo = "Please fill in PageInfo ";
    }
    return errors;
  };

  const handleSave = async () => {
    try {
      const errors = validateInputs();
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const token = getCookie("Authorization");
        const uploadResponse = await fetch(`${apiUrl}/upload`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: formData,
        });
        if (!uploadResponse.ok) {
          messageFunc("error", "Failed to upload file");
          return;
        }
        const uploadData = await uploadResponse.json();
        const icon = uploadData.response.data;
        aboutData.icon = icon;
      }

      const token = getCookie("Authorization");
      fetch(`${apiUrl}/admin/about-us`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(aboutData),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
          setAboutData({
            icon: response.icon,
            companyName: response.companyName,
            info: response.info,
            phone: response.phone,
            email: response.email,
            locationText: response.locationText,
            locationLink: response.locationLink,
            pageInfo: response.pageInfo,
          });
            navigate("/adminpanel/about");
          } else {
            messageFunc("error", "Failed to update About Us");
          }
        });
    } catch (error) {
      console.error("Error updating About Us:", error);
    }
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              // items={sidebarItems}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>About Edit</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  style={{ fontSize: "1.4rem" }}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 300,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form
                scroll={{ y: tableHeight - 250, x: 400 }}
                className="sub-layout"
              >
                <Form.Item
                  validateStatus={errors.companyName ? "error" : ""}
                  help={errors.companyName}
                >
                  <Input
                    placeholder="Company Name"
                    name="companyName"
                    value={aboutData.companyName}
                    onChange={handleInputChange}
                  />
                </Form.Item>

                <Form.Item
                  validateStatus={errors.info ? "error" : ""}
                  help={errors.info}
                >
                  <TextArea
                    placeholder="Info"
                    name="info"
                    value={aboutData.info}
                    onChange={handleInputChange}
                  />
                </Form.Item>

                <Form.Item
                  validateStatus={errors.phone ? "error" : ""}
                  help={errors.phone}
                >
                  <Input
                    placeholder="Phone"
                    name="phone"
                    value={aboutData.phone}
                    onChange={handleInputChange}
                  />
                </Form.Item>

                <Form.Item
                  validateStatus={errors.email ? "error" : ""}
                  help={errors.email}
                >
                  <Input
                    placeholder="Email"
                    name="email"
                    value={aboutData.email}
                    onChange={handleInputChange}
                  />
                </Form.Item>

                <Form.Item
                  validateStatus={errors.locationText ? "error" : ""}
                  help={errors.locationText}
                >
                  <Input
                    placeholder="Location Text"
                    name="locationText"
                    value={aboutData.locationText}
                    onChange={handleInputChange}
                  />
                </Form.Item>

                <Form.Item
                  validateStatus={errors.locationLink ? "error" : ""}
                  help={errors.locationLink}
                >
                  <TextArea
                    placeholder="Location Link"
                    name="locationLink"
                    value={aboutData.locationLink}
                    onChange={handleInputChange}
                  />
                </Form.Item>

                <Form.Item
                  validateStatus={errors.pageInfo ? "error" : ""}
                  help={errors.pageInfo}
                >
                  <TextArea
                    placeholder="Page Info"
                    name="pageInfo"
                    value={aboutData.pageInfo}
                    onChange={handleInputChange}
                  />
                </Form.Item>

                <Form.Item valuePropName="file">
                  <input type="file" id="file" onChange={handleFileChange} accept="image/*" />
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default AboutEdit;
