import React, { useEffect, useRef, useState } from "react";
import { getColumnSearchProps } from "../../functions/search";
import { Space, Table } from "antd";
import {
  EditOutlined,
  ProjectOutlined,
  FundProjectionScreenOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import apiUrl from "../../api/axios";
import { getCookie } from "../../functions/token";
import { Footer } from "antd/es/layout/layout";
import AdminHeader from "./AdminHeader";
import { sidebarItems } from "../../functions/sidebar";
import defaultCv from "../../img/cvdefault.jpg"
import defaultPP from "../../img/ppdefault.jpg"
const { Content, Sider } = Layout;

const App = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [userData, setUserData] = useState([]);
  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setTableHeight(window.innerHeight);
    });
  }, []);

  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((userData) => {
        const users = userData.response.data;
        setUserData(users);
      })
      .catch((error) => {
        console.log("Error fetching user data:", error);
      });
  }, []);


  const expandedRowRender = (record) => {
    const user = userData.find((item) => item.id === record.key);
    const category = user.category;
    if (Object.values(user).every((value) => value === null)) {
      return <span>No Data</span>;
    }
    const columns = [
      { title: "Birthday", dataIndex: "birthday", key: "birthday" },
      { title: "Phone", dataIndex: "phone", key: "phone" },
      {
        title: "Github",
        dataIndex: "github",
        key: "github",
        ellipsis: { showTitle: true },
        render: (text, record) => (
          <a href={text} target="_blank" rel="noopener noreferrer">
            {text}{" "}
          </a>
        )
      },
      {
        title: "Linkedin",
        dataIndex: "linkedin",
        key: "linkedin",
        ellipsis: { showTitle: true },
        render: (text, record) => (
          <a href={text} target="_blank" rel="noopener noreferrer">
            {text}{" "}
          </a>
        )
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        render: (category) => (category ? category.name : ""),
      },
      {
        title: "CV",
        dataIndex: "cv",
        key: "cv",
        render: (fileSrc) => {
          if (!fileSrc || fileSrc.endsWith(".rar") || fileSrc.endsWith(".zip") || fileSrc.endsWith(".pdf")) {
            return (
              <a href={`${apiUrl}/uploads/${fileSrc}`} download target="_blank" 
              rel="noopener noreferrer"
              >
                {/* <img alt="" src={defaultCv} style={{ height: "80px" }} /> */}
              </a>
            );
          } else {
            return (
              <a href={`${apiUrl}/uploads/${fileSrc}`} download target="_blank"
              rel="noopener noreferrer"
              style={{textDecoration:"underline"}}
              >
                Download CV↗
              </a>
            );
          }
        },
      },
      {
        title: "Image",
        dataIndex: "image",
        key: "image",
        render: (fileSrc) => {
          if (!fileSrc) {
            return (
              <img alt="" src={defaultPP} style={{ height: "80px" }} />
            );
          } else {
            return (
              <img
                alt=""
                onClick={() =>
                  window.open(`${apiUrl}/uploads/${fileSrc}`, "_blank")
                }
                src={`${apiUrl}/uploads/${fileSrc}`}
                style={{ height: "80px" }}
              />
            );
          }
        },
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={[user]}
        pagination={false}
        rowClassName="table-row-with-border"
      />
    );
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      width: 60,
      render: (_, record, index) => index + 1,
      className: "center-align",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)
    },
    {
      title: "Surname",
      dataIndex: "surname",
      key: "surname",
      ...getColumnSearchProps("surname", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ...getColumnSearchProps("username", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      ...getColumnSearchProps("role", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)
    },
    {
      title: "Active",
      dataIndex: "isDeleted",
      key: "isDeleted",
      width: 100,
      render: (isDeleted) => (isDeleted === 0 ? "true" : "false"),
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{ color: "blue" }}
            href={`/adminpanel/useredit/${record.key}`}
          >
            <EditOutlined />
          </Button>
          <Button
            style={{ color: "blue" }}
            title="project"
            href={`/adminpanel/userproject/${record.key}`}
          >
            <ProjectOutlined />
          </Button>
          <Button
            style={{ color: "blue" }}
            title="task"
            href={`/adminpanel/usertask/${record.key}`}
          >
            <FundProjectionScreenOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const data = userData.map((user, index) => ({
    key: user.id,
    name: user.name,
    surname: user.surname,
    username: user.username,
    role: user.role,
    isDeleted: user.isDeleted,
  }));

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleItemClick = (href) => {
    navigate(href);
  };

  return (
    <>
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Users</p>
            </div>
            <Content
              style={{
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Table
                columns={columns}
                dataSource={data}
                expandable={{
                  expandedRowRender: (record) => expandedRowRender(record),
                  defaultExpandedRowKeys: ["0"],
                }}
                bordered
                pagination={false}
                scroll={{ y: tableHeight - 250, x: 1000 }}
                className="sub-layout"
              />
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default App;
