import React, { useState } from "react";
import { Button, Flex, Form, Input, Layout, Menu, message, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import TextArea from "antd/es/input/TextArea";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const ServicesAdd = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const [services, setServices] = useState([]);
  const [name, setName] = useState("");
  const [info, setInfo] = useState("");
  const [errors, setErrors] = useState("");

  const addServices = () => {
    const errors = {};
    if (!name) {
      errors.name = "Please fill in Name";
    }
    if (!info) {
      errors.info = "Please fill in Description";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/it-info-category`, {
      method: "POST",
      body: JSON.stringify({
        name,
        info,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc("error", "Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        setServices([...services, data]);
        setName("");
        setInfo("");
        navigate("/adminpanel/services");
      });
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Services Add</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  onClick={addServices}
                  style={{ fontSize: "1.4rem" }}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                overflowY: "auto",
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Form scroll={{ x: 400 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    placeholder="Name"
                    onChange={(e) => {
                      setName(e.target.value);
                      setErrors({ ...errors, name: null });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.info ? "error" : ""}
                  help={errors.info}
                >
                  <TextArea
                    placeholder="Description"
                    onChange={(e) => {
                      setInfo(e.target.value);
                      setErrors({ ...errors, info: null });
                    }}
                  />
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default ServicesAdd;
