import React from 'react'

const Package = ({course}) => {
  return (
  <>
  <div className="card">
        <h3>{course.name}</h3>
        <p>{course.time}</p>
        <ul>
          <li><i className="fa-solid fa-check"></i>{course.item1}</li>
          <li><i className="fa-solid fa-check"></i>{course.item2}</li>
          <li><i className="fa-solid fa-check"></i>{course.item3}</li>
        </ul>
        <button>{course.cost}</button>
    </div> 
  </>
  )
}

export default Package