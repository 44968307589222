import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import CourseCard from "./CourseCard";
import apiUrl from '../api/axios';

const Courses = ({ card }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`${apiUrl}/site/it-info-category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        const data = response.response.data;
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);    

  if(data.length===0) {
    return null
  }

  return (
    <>
      <div className={data ? 'wrapper-courses' : 'wrapper-courses hidden'}>
        <h3 className='ourservices'>Our Services</h3>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="swiper"
        >

          {data && data.map(d => {
            return (
              <SwiperSlide className='swiperslide' key={d.id}>
                <CourseCard
                  key={d.id}
                  id={d.id}
                  name={d.name}
                  info={d.info}
                />
              </SwiperSlide>
            )
          })}

        </Swiper>

        <Swiper
          slidesPerView="auto"
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="swiper-media"
        >
          {data && data.map(d => {
            return (
              <SwiperSlide className='swiperslide' key={d.id}>
                <CourseCard
                  key={d.id}
                  id={d.id}
                  name={d.name}
                  info={d.info}
                  itInfos={d.itInfos}
                  datax={d}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>

      </div>
    </>
  )
}

export default Courses

