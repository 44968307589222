import React, { useEffect, useState } from "react";
import { Button, Flex, Form, Input, Layout, Menu, message, theme } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import apiUrl from "../../api/axios";
import { getCookie } from "../../functions/token";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import TextArea from "antd/es/input/TextArea";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const ServicesEdit = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { id } = useParams();

  const [serviceData, setServiceData] = useState({
    id: id,
    name: "",
    info: "",
  });
  const [errors, setErrors] = useState("");

  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };
  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/site/it-info-category/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc("error", "Network response was not ok");
        }
        return res.json();
      })
      .then((serviceData) => {
        const services = serviceData.response.data;
        setServiceData({
          name: services.name,
          info: services.info,
        });
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [id]);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setServiceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateInputs = () => {
    const errors = {};
    if (!serviceData.name) {
      errors.name = "Please fill in Name";
    }
    if (!serviceData.info) {
      errors.info = "Please fill in Description";
    }
    return errors;
  };
  const serviceEdit = () => {
    const errors = validateInputs();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/it-info-category`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        id: id,
        name: serviceData.name,
        info: serviceData.info,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc("error", "Network response was not ok");
        }
        return res.json();
      })
      .then((resp) => {
        setServiceData({
          id: serviceData.id,
          name: serviceData.name,
          info: serviceData.info,
        });
        navigate("/adminpanel/services");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              // items={sidebarItems}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Services Edit</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  onClick={serviceEdit}
                  style={{ fontSize: "1.4rem" }}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form scroll={{ x: 400 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    placeholder="Name"
                    name="name"
                    value={serviceData.name}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.info ? "error" : ""}
                  help={errors.info}
                >
                  <TextArea
                    placeholder="Description"
                    name="info"
                    value={serviceData.info}
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default ServicesEdit;
