import { Button, Flex, Layout, Menu, Modal, Space, Table, theme } from "antd";
import Column from "antd/es/table/Column";
import React, { useEffect, useRef, useState } from "react";
import { DeleteOutlined, MailOutlined } from "@ant-design/icons";
import AdminHeader from "./AdminHeader";
import Sider from "antd/es/layout/Sider";
import { useNavigate } from "react-router-dom";
import { Content, Footer } from "antd/es/layout/layout";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import { sidebarItems } from "../../functions/sidebar";
import { getColumnSearchProps } from "../../functions/search";

const Email = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [data, setData] = useState([]);
  const [count, setDataCount] = useState([]);
  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setTableHeight(window.innerHeight);
    });
  }, []);

  const fetchUserData = () => {
    const token = getCookie("Authorization");

    fetch(`${apiUrl}/admin/email`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const datas = data.response.data;
        setData(datas);
      })
      .catch((error) => {
        console.log("Error fetching user data:", error);
      });
  };

  useEffect(() => {
    if (searchText === "") {
      fetchUserData();
    }
  }, [searchText]);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const handleDeleteAll = () => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/email/clear`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        setData("");
        setDataCount("0");
        navigate("/adminpanel/email");
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };

  const handleDelete = (id) => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/email/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        setData((prevData) => prevData.filter((item) => item.id !== id));
        navigate("/adminpanel/email");
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const showModal = (id) => {
    setIsModalVisible(true);
    setDeleteItemId(id);
  };

  const handleOk = () => {
    handleDelete(deleteItemId);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/number-of-unread`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const data = response.response.data;
        setDataCount(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <Layout className="main-layout" key="main-layout">
      <AdminHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Layout key="layoutt">
        <Sider
          key="sider"
          width={200}
          style={{
            background: colorBgContainer,
            display: sidebarOpen ? "block" : "none",
          }}
        >
          <Menu
            key="menu"
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ borderRight: 0 }}
            items={sidebarItems.map((item) => ({
              ...item,
              onClick: () => handleItemClick(item.href),
            }))}
          />
        </Sider>
        <Layout key="layouttt" style={{ padding: "0 2rem 1rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBlock: "1rem",
            }}
          >
            <p style={{ fontSize: "1.6rem" }}>Email</p>
            <Flex key="flex" justify="flex-end" align="center">
              <Button
                key="btn"
                danger
                style={{ marginRight: "1rem" }}
                onClick={handleDeleteAll}
              >
                Delete All
              </Button>
            </Flex>
          </div>
          <Content
            key="content"
            style={{
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              overflowY: "auto",
            }}
          >
            <Table
              key="table"
              dataSource={data}
              bordered
              pagination={false}
              scroll={{ y: tableHeight - 250, x: 400 }}
              className="sub-layout"
            >
              <Column
                title="No"
                key="index"
                width={60}
                className="center-align"
                render={(text, record, index) => <span>{index + 1}</span>}
              />
              <Column
                title="Email"
                dataIndex="email"
                key="email"
                ellipsis={{ showTitle: true }}
                {...getColumnSearchProps(
                  "email",
                  searchText,
                  setSearchText,
                  searchedColumn,
                  setSearchedColumn,
                  searchInput
                )}
                render={(text, record) => (
                  <span
                    style={{
                      fontWeight: record.status === 1 ? "bold" : "normal",
                    }}
                  >
                    <a href={`mailto:${text}`}>{text}</a>
                  </span>
                )}
              />
              <Column
                title="Subject"
                dataIndex="subject"
                key="subject"
                {...getColumnSearchProps(
                  "subject",
                  searchText,
                  setSearchText,
                  searchedColumn,
                  setSearchedColumn,
                  searchInput
                )}
                ellipsis={{ showTitle: true }}
                render={(text, record) => (
                  <span
                    style={{
                      fontWeight: record.status === 1 ? "bold" : "normal",
                    }}
                  >
                    {text}
                  </span>
                )}
              />
              <Column
                title="Message"
                dataIndex="message"
                key="message"
                {...getColumnSearchProps(
                  "message",
                  searchText,
                  setSearchText,
                  searchedColumn,
                  setSearchedColumn,
                  searchInput
                )}
                ellipsis={{ showTitle: true }}
                render={(text, record) => (
                  <span
                    style={{
                      fontWeight: record.status === 1 ? "bold" : "normal",
                    }}
                  >
                    {text}
                  </span>
                )}
              />
              <Column
                title="Date"
                dataIndex="date"
                key="date"
                {...getColumnSearchProps("date", searchText,
                setSearchText,
                searchedColumn,
                setSearchedColumn,
                searchInput)}
                render={(text, record) => (
                  <span
                    style={{
                      fontWeight: record.status === 1 ? "bold" : "normal",
                    }}
                  >
                    {text}
                  </span>
                )}
              />
              <Column
                title="Action"
                key="action"
                width={150}
                render={(_, record) => (
                  <Space key="space" size="middle">
                    <Button
                      key="button"
                      style={{ color: "blue", padding: "4px 18px" }}
                      href={`/adminpanel/emailDetails/${record.id}`}
                    >
                      <MailOutlined />
                    </Button>
                    <div>
                      <Button
                        key="buton"
                        danger
                        style={{ color: "red" }}
                        onClick={() => {
                          showModal(record.id);
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                      <Modal
                        key={`modal-${record.id}`}
                        title="Delete Email"
                        open={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                      >
                        <p>Are you sure you want to delete all emails?</p>
                      </Modal>
                    </div>
                  </Space>
                )}
              />
            </Table>
          </Content>
          <Footer
            key="footer"
            style={{ textAlign: "center", paddingBlock: "1rem" }}
          >
            Created by NSP SOLUTIONS ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Email;
