import React, { useEffect, useRef, useState } from "react";
import { getColumnSearchProps } from "../../functions/search";
import { Modal, Space, Table, message } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import apiUrl from "../../api/axios";
import { getCookie } from "../../functions/token";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const UserProject = () => {
  const { id: userId } = useParams();   
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [projectData, setProjectData] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/task-of-user/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc("error", "Network response was not ok");
        }
        return res.json();
      })
      .then((projectData) => {
        const project = projectData.response.data;
        setProjectData(project);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);


  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };


  const data = projectData.map((project, index) => ({
    key: project.id,
    taskId: project.id,
    taskName: project.name,
    taskDescription: project.description,
    taskFile: project.file,
  }));


  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      width: 60,
      render: (_, record, index) => index + 1,
      className: "center-align",
    },
    { title: "Task Name", 
    dataIndex: "taskName", 
    key: "taskName",
    ...getColumnSearchProps("taskName", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)

  },
    {
      title: "Task Description",
      dataIndex: "taskDescription",
      key: "taskDescription",
      ellipsis: { showTitle: true },
      ...getColumnSearchProps("taskDescription", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)
    },
    {
      title: "Task File",
      dataIndex: "taskFile",
      key: "taskFile",
      render: (fileSrc) => (
        <a href={`${apiUrl}/uploads/${fileSrc}`} target="_blank" rel="noreferrer" download style={{textDecoration:"underline"}}>Download file↗</a>
      ),
    },
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{ color: "blue" }}
            href={`/adminpanel/taskEdit/${record.taskId}`}
          >
            <EditOutlined />
          </Button>
          <div>
            <Button
              key="buton"
              danger
              style={{ color: "red" }}
              onClick={() => {
                showModal(record.taskId);
              }}
            >
              <DeleteOutlined />
            </Button>
            <Modal
              key={`modal-${record.id}`}
              title="Delete"
              open={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <p>Are you sure you want to delete?</p>
            </Modal>
          </div>
        </Space>
      ),
    },
  ];

  const handleDelete = (id) => {
    const token = getCookie("Authorization");
    const task = projectData.find(item => item.id === id);
    fetch(`${apiUrl}/admin/task-user`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify({ userId: userId, taskId: task.id }),
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc("error", "Network response was not ok");
        }
        return res.json();
      })
      .then((resp) => {
        setProjectData(projectData.filter((resp) => resp.id !== id));
        navigate(`/adminpanel/usertask`);
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const showModal = (id) => {
    setIsModalVisible(true);
    setDeleteItemId(id);
  };
  const handleOk = () => {
    handleDelete(deleteItemId);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setTableHeight(window.innerHeight);
    });
  }, []);

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>User Task</p>
            </div>
            <Content
              style={{
                overflowY: "auto",
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Table
                columns={columns}
                dataSource={data}
                bordered
                pagination={false}
                scroll={{ y: tableHeight - 250, x: 800 }}
                className="sub-layout"
              />
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default UserProject;
