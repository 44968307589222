import { useParams } from 'react-router-dom';
import ReadmoreCard from '../components/ReadmoreCard';
import React, { useEffect, useState } from 'react';
import apiUrl from '../api/axios';

const ReadMore = () => {
  const { id } = useParams();
  const [read, setRead] = useState([]);

  useEffect(() => {
    const fetchProjectsOfUser = async () => {
      try {
        const response = await fetch(`${apiUrl}/site/it-info-of-category/${id}`, {
          method: 'GET',
        });

        if (response.ok) {
          const data = await response.json();
          setRead(data.response.data);
        }
      } catch (error) {
        console.log(`Error`);
      }
    };

    fetchProjectsOfUser();
  }, [id]);

  if (!read.length) {
    return <p style={{ fontSize: 15, textAlign: "center", margin: 25 }}>No data available</p>;
  }

  return (
    <>
      <div className="read-more">
          <>
            <h3>{read[0].itInfoCategory.name}</h3>
            <div className='cat-info'>
              <p className='mt-10 read-p'>{read[0].itInfoCategory.info}</p>
            </div>
          </>
        <div className="card-items" id='card-items'>
          <div className="items">
            {read.map(x => (
              <ReadmoreCard name={x.name} info={x.info} key={x.id} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReadMore;
