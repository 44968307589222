import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Form,
  Input,
  Layout,
  Menu,
  Select,
  message,
  theme,
} from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import TextArea from "antd/es/input/TextArea";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const ReadMoreAdd = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const [name, setName] = useState("");
  const [info, setInfo] = useState("");
  const [itInfoCategoryId, setItInfoCategoryId] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      const token = getCookie("Authorization");
      try {
        const response = await fetch(`${apiUrl}/site/it-info-category`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${token}`,
          },
        })
          .then((res) => {
            if (!res.ok) {
              messageFunc("error", "Network response was not ok!");
            }
            return res.json();
          })
          .then((data) => {
            setCategories(data.response.data);
          });
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const validateInputs = () => {
    const errors = {};
    if (!name) {
      errors.name = "Please enter a Header";
    }
    if (!info) {
      errors.info = "Please enter a Info";
    }
    if (!itInfoCategoryId) {
      errors.itInfoCategoryId = "Please select a category";
    }
    return errors;
  };

  const addReadMore = () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/it-info`, {
      method: "POST",
      body: JSON.stringify({
        name,
        info,
        itInfoCategoryId,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc("error", "Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        setName("");
        setInfo("");
        setItInfoCategoryId("");
        navigate("/adminpanel/readmore");
      });
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Read More Add</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  onClick={addReadMore}
                  style={{ fontSize: "1.4rem" }}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form scroll={{ x: 400 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    placeholder="Header"
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.info ? "error" : ""}
                  help={errors.info}
                >
                  <TextArea
                    placeholder="Info"
                    name="info"
                    onChange={(e) => setInfo(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.itInfoCategoryId ? "error" : ""}
                  help={errors.itInfoCategoryId}
                >
                  <Select
                    placeholder="Select a category"
                    onChange={(value) => setItInfoCategoryId(value)}
                  >
                    {categories.map((category) => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default ReadMoreAdd;
