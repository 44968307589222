import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  LockOutlined,
  RightOutlined
} from "@ant-design/icons";

const sidebarItems = [
    {
      key: "sub1",
      icon: <RightOutlined />,
      label: "About",
      href: "/adminpanel/about",
    },
    {
      key: "sub2",
      icon: <RightOutlined />,
      label: "Category",
      href: "/adminpanel/category",
    },
    {
      key: "sub3",
      icon: <RightOutlined />,
      label: "Services",
      href: "/adminpanel/services",
    },
    {
      key: "sub4",
      icon: <RightOutlined />,
      label: "Read More",
      href: "/adminpanel/readmore",
    },
    {
      key: "sub5",
      icon: <RightOutlined />,
      label: "Education",
      href: "/adminpanel/education",
    },
    {
      key: "sub6",
      icon: <RightOutlined />,
      label: "Company Project",
      href: "/adminpanel/companyproject",
    },
    {
      key: "sub7",
      icon: <RightOutlined />,
      label: "Intern Project",
      href: "/adminpanel/internproject",
    },
    {
      key: "sub8",
      icon: <RightOutlined />,
      label: "User",
      href: "/adminpanel/user",
    },
    {
      key: "sub9",
      icon: <RightOutlined />,
      label: "Task",
      href: "/adminpanel/task",
    },
    {
      key: "sub10",
      icon: <RightOutlined />,
      label: "Project",
      href: "/adminpanel/project",
    },
    {
      key: "sub11",
      icon: <RightOutlined />,
      label: "Task User",
      href: "/adminpanel/taskUser",
    },
    {
      key: "sub12",
      icon: <RightOutlined />,
      label: "Email",
      href: "/adminpanel/email",
    },
  ];

  const sidebarItem = [
    {
      key: "sub1",
      icon: <UserOutlined />,
      label: "Profile",
      href: "/profile",
    },
    {
      key: "sub2",
      icon: <LaptopOutlined />,
      label: "Projects",
      href: "/projects",
    },
    {
      key: "sub3",
      icon: <NotificationOutlined />,
      label: "Tasks",
      href: "/tasks",
    },
    {
      key: "sub4",
      icon: <LockOutlined />,
      label: "Change Password",
      href: "/changepassword",
    },
  ];
  

export {sidebarItems, sidebarItem};