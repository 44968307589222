import React, { useEffect, useState } from "react";
import {
  UserOutlined,
  LogoutOutlined,
  DeleteOutlined,
  ProfileOutlined
} from "@ant-design/icons";
import { Avatar, Menu, Layout, Dropdown, Modal } from "antd";
import apiUrl from "../api/axios";
import { clearToken, getCookie } from "../functions/token";
import { useNavigate } from "react-router-dom";
import defaultImg from "../img/ppdefault.jpg"
const { Header } = Layout;

const MenuHeader = ({ onProfileClick, sidebarOpen, setSidebarOpen }) => {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/profile/info`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`
      }
    })
      .then((res) => res.json())
      .then((userData) => {
        const user = userData.response.data;
        setUserData(user);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const handleMenuClick = ({ key }) => {
    if (key === "logout") {
      clearToken();
      navigate("/");
    }
    if (key === "profile") {
      navigate("/profile");
    }
    setOpen(false);
  };

  const deleteUser = () => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/profile/user`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          alert("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error deleting account:", error);
      });
  };


  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/profile/get-role`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`
      }
    })
      .then((res) => res.json())
      .then((data) => {
        const role = data.response.data;
        setUserRole(role);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);


  const handleDeleteUser = () => {
    setDeleteModalVisible(true);
  };

  const handleConfirmDelete = () => {
    deleteUser();
    setDeleteModalVisible(false);
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined />}
        style={{ color: "red" }}
      >
        Logout
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        style={{ color: "red" }}
        onClick={handleDeleteUser}
      >
        Delete User
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ display: "flex", alignItems: "center" }}>
      <div className="demo-logo" />
      <a href="/" style={{ color: "#fff", padding: "0rem 1rem", fontSize: "1.3rem"}}>
        Home
      </a>
      <a
        onClick={onProfileClick}
        type="link"
        style={{
          color: "#fff",
          backgroundColor: "#1677FF",
          margin: "0 2rem",
          padding: "0rem 2rem",
          borderRadius: "0",
          fontSize: "1.3rem"
        }}
      >
        Profile
      </a>
      {userRole === "ADMIN" && (
        <a href="/adminpanel/about" style={{ color: "#fff", padding: "0rem 1rem", fontSize: "1.3rem" }}>
          Admin Panel
        </a>
      )}
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={["2"]}
        style={{ flex: 1, minWidth: 0 }}
      />
      <li style={{ fontSize: 13, marginRight: 20, color: "whitesmoke"}}>{userData.name + " " + userData.surname}</li>
      <Dropdown
        key="userDropdown"
        overlay={menu}
        trigger={["click"]}
        open={open}
        onOpenChange={(flag) => setOpen(flag)}
      >
        <Avatar
          size="large"
          icon={<UserOutlined />}
          style={{ border: "1px solid #fff", cursor: "pointer" }}
          src={userData.image ? `${apiUrl}/uploads/${userData.image}` : defaultImg}
          onClick={() => setOpen(!open)}
        />
      </Dropdown>
      <Modal
        key="modal"
        title="Are you sure?"
        open={deleteModalVisible}
        onOk={handleConfirmDelete}
        onCancel={handleCancelDelete}
      >
        <p>Are you sure you want to delete your account?</p>
      </Modal>
    </Header>
  );
};

export default MenuHeader;
