import React, { useEffect, useState } from "react";
import { Button, Flex, Form, Input, Layout, Menu, theme } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const EducationEdit = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { id } = useParams();

  const [eduData, setEduData] = useState({
    id: id,
    name: "",
    item1: "",
    item2: "",
    item3: "",
    time: "",
    cost: "",
  });
  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/site/course/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((eduData) => {
        const course = eduData.response.data;
        setEduData({
          name: course.name,
          item1: course.item1,
          item2: course.item2,
          item3: course.item3,
          time: course.time,
          cost: course.cost,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEduData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [errors, setErrors] = useState({});
  const validateInputs = () => {
    const errors = {};

    if (!eduData.name) {
      errors.name = "Please fill in Name";
    }
    if (!eduData.time) {
      errors.time = "Please fill in Time";
    }
    if (!eduData.cost) {
      errors.cost = "Please fill in Cost";
    }
    if (!eduData.item1) {
      errors.item1 = "Please fill in Item1";
    }
    if (!eduData.item2) {
      errors.item2 = "Please fill in Item2";
    }
    if (!eduData.item3) {
      errors.item3 = "Please fill in Item3";
    }

    return errors;
  };

  const eduEdit = () => {
    const errors = validateInputs();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/course`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        id: id,
        name: eduData.name,
        item1: eduData.item1,
        item2: eduData.item2,
        item3: eduData.item3,
        time: eduData.time,
        cost: eduData.cost,
      }),
    })
      .then((res) => res.json())
      .then((course) => {
        setEduData({
          id: id,
          name: course.name,
          item1: course.item1,
          item2: course.item2,
          item3: course.item3,
          time: course.time,
          cost: course.cost,
        });
        navigate("/adminpanel/education");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  return (
    <Layout className="main-layout">
      <AdminHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
            display: sidebarOpen ? "block" : "none",
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ borderRight: 0 }}
            items={sidebarItems.map((item) => ({
              ...item,
              onClick: () => handleItemClick(item.href),
            }))}
          />
        </Sider>
        <Layout style={{ padding: "0 2rem 1rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBlock: "1rem",
            }}
          >
            <p style={{ fontSize: "1.6rem" }}>Education Edit</p>
            <Flex justify="flex-end" align="center">
              <Button
                type="primary"
                onClick={eduEdit}
                style={{ fontSize: "1.4rem" }}
              >
                Save
              </Button>
            </Flex>
          </div>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              overflowY: "auto",
            }}
          >
            <Form scroll={{ x: 400 }} className="sub-layout">
              <Form.Item
                validateStatus={errors.name ? "error" : ""}
                help={errors.name}
              >
                <Input
                  placeholder="Name"
                  value={eduData.name}
                  name="name"
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors.time ? "error" : ""}
                help={errors.time}
              >
                <Input
                  placeholder="Time"
                  value={eduData.time}
                  name="time"
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors.cost ? "error" : ""}
                help={errors.cost}
              >
                <Input
                  placeholder="Cost"
                  value={eduData.cost}
                  name="cost"
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors.item1 ? "error" : ""}
                help={errors.item1}
              >
                <Input
                  placeholder="Item 1"
                  value={eduData.item1}
                  name="item1"
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors.item2 ? "error" : ""}
                help={errors.item2}
              >
                <Input
                  placeholder="Item 2"
                  value={eduData.item2}
                  name="item2"
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors.item3 ? "error" : ""}
                help={errors.item3}
              >
                <Input
                  placeholder="Item 3"
                  value={eduData.item3}
                  name="item3"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Form>
          </Content>
          <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
            Created by NSP SOLUTIONS ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default EducationEdit;
