import React, { useEffect, useState } from 'react';
import {
  UserOutlined, LaptopOutlined,
  NotificationOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { Button, Flex, Form, Input, Layout, Menu, Select, message, theme } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import apiUrl from '../../api/axios';
import { getCookie } from '../../functions/token';
import MenuHeader from '../../pages/MenuHeader';
import { Footer } from 'antd/es/layout/layout';
import TextArea from 'antd/es/input/TextArea';
const { Content, Sider } = Layout;

const sidebarItems = [
  {
    key: "sub1",
    icon: <UserOutlined />,
    label: "Profile",
    href: "/profile",
  },
  {
    key: "sub2",
    icon: <LaptopOutlined />,
    label: "Projects",
    href: "/projects",
  },
  {
    key: "sub3",
    icon: <NotificationOutlined />,
    label: "Tasks",
    href: "/tasks",
  },
  {
    key: "sub4",
    icon: <LockOutlined />,
    label: "Change Password",
    href: "/changepassword",
  },

];

const ProjectEdit = () => {
  const { id } = useParams();
  const [projectData, setProjectData] = useState({
    id: id,
    file: "",
    name: "",
    description: "",
    link: "",
    mark: "",
    taskId: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [errors, setErrors] = useState({});
  const validateInputs = () => {
    const errors = {};

    if (!projectData.name) {
      errors.name = "Please fill in Name";
    }
    if (!projectData.description) {
      errors.description = "Please fill in Info";
    }
    if (!projectData.link) {
      errors.link = "Please fill in Link";
    }
    return errors;
  };

  useEffect(() => {
    const token = getCookie('Authorization');
    fetch(`${apiUrl}/profile/project/${id}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc('error', 'Network response was not ok');
        }
        return res.json();
      })
      .then((projectData) => {
        const project = projectData.response.data;
        setProjectData(project);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [id]);

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTaskChange = (value) => {
    setProjectData((prevData) => ({
      ...prevData,
      taskId: value,
    }));
  };



  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchTasks = async () => {
      const token = getCookie('Authorization');
      try {
        const response = await fetch(`${apiUrl}/profile/task-user`, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTasks(data.response.data);
      } catch (error) {
        messageFunc('error', 'Error fetching tasks');
      }
    };

    fetchTasks();
  }, []);


  const handleSave = async () => {
    try {
      const errors = validateInputs();
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const token = getCookie("Authorization");
        const uploadResponse = await fetch(`${apiUrl}/upload`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: formData,
        });
        if (!uploadResponse.ok) {
          messageFunc("error", "Failed to upload file");
        }
        const uploadData = await uploadResponse.json();
        const uploadedFile = uploadData.response.data;
        projectData.file = uploadedFile;
      }
      const token = getCookie("Authorization");
      const response = await fetch(`${apiUrl}/profile/project`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `${token}`,
        },
        body: JSON.stringify({
          id: id,
          file: projectData.file,
          name: projectData.name,
          description: projectData.description,
          taskId: projectData.taskId,
          link: projectData.link,
        }),
      });
      if (!response.ok) {
        messageFunc("error", "Failed to update data");
      }
      const responseData = await response.json();
      setProjectData({
        id: responseData.id,
        file: responseData.file,
        name: responseData.name,
        description: responseData.description,
        taskId: responseData.taskId ? responseData.taskId : null,
        link: responseData.link
      });
      if (responseData.status === 200) {
        navigate("/projects");
      } else {
        messageFunc("error", "Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  console.log(projectData);
  return (
    <>
      {contextHolder}
      <Layout className='main-layout'>
        <MenuHeader />
        <Layout>
          <Sider width={200} style={{ background: colorBgContainer, display: sidebarOpen ? "block" : "none", }}>
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
            >
              {sidebarItems.map((item) => (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  onClick={() => handleItemClick(item.href)}
                >
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Project Edit</p>
              <Flex justify="flex-end" align="center">
                <Button type="primary" onClick={handleSave} style={{ fontSize: "1.4rem" }}>Save</Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                overflowY: "auto",
                borderRadius: borderRadiusLG,
              }}
            >
              <Form scroll={{ x: 800 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input placeholder='Name' value={projectData.name} name='name' onChange={handleInputChange} />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.description ? "error" : ""}
                  help={errors.description}
                >
                  <TextArea placeholder='Description' value={projectData.description} name='description' onChange={handleInputChange} />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.link ? "error" : ""}
                  help={errors.link}
                >
                  <Input placeholder='Link' value={projectData.link} name='link' onChange={handleInputChange} />
                </Form.Item>

                <Form.Item>
                  <Select
                    placeholder="Select Task"
                    value={projectData.taskName}
                    onChange={handleTaskChange}
                  >
                    {tasks.map(task => (
                      <Select.Option key={task.task.id} value={task.task.id}>
                        {task.task.name} 
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="File" valuePropName="file">
                  <input type="file" id="file" onChange={handleFileChange} />
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>

  );
};

export default ProjectEdit;
