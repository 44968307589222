import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Form,
  Input,
  Layout,
  Menu,
  Select,
  message,
  theme,
} from "antd";
import { useNavigate } from "react-router-dom";
import apiUrl from "../../api/axios";
import { getCookie } from "../../functions/token";
import { useParams } from "react-router-dom/dist";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import TextArea from "antd/es/input/TextArea";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;


const ReadMoreEdit = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { id } = useParams();
  const [readmoreData, setReadmoreData] = useState({
    id: id,
    name: "",
    info: "",
    itInfoCategoryId: "",
  });
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");


  // useEffect(() => {
  //   const fetchCategory = async () => {
  //     try {
  //       const token = getCookie("Authorization");
  //       const response = await fetch(`${apiUrl}/site/it-info/${id}`, {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `${token}`,
  //         },
  //       });
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok!");
  //       }
  //       const data = await response.json();
  //       const readmore = data.response.data;
  //       setReadmoreData({
  //         id: id,
  //         name: readmore.name,
  //         info: readmore.info,
  //         itInfoCategoryId: readmore.itInfoCategoryId,
  //       });
  //       fetchCategories();
  //     } catch (error) {
  //       console.error("Error fetching category:", error);
  //       messageFunc("error", "Failed to fetch category. Please try again later.");
  //     }
  //   };

  //   const fetchCategories = async () => {
  //     try {
  //       const response = await fetch(`${apiUrl}/site/it-info-category`, {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       });
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok!");
  //       }
  //       const res = await response.json();
  //       const data = res.response.data;
  //       setCategories(data);
  //     } catch (error) {
  //       messageFunc("error", "Failed to fetch categories. Please try again later.");
  //     }
  //   };

  //   fetchCategory();
  // }, [id]);


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${apiUrl}/site/it-info-category`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok!");
        }
        const res = await response.json();
        const data = res.response.data;
        setCategories(data);
      } catch (error) {
        messageFunc("error", "Failed to fetch categories. Please try again later.");
      }
    };
  
    const fetchCategory = async () => {
      try {
        const token = getCookie("Authorization");
        const response = await fetch(`${apiUrl}/site/it-info/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok!");
        }
        const data = await response.json();
        const readmore = data.response.data;
        setReadmoreData({
          id: id,
          name: readmore.name,
          info: readmore.info,
          itInfoCategoryId: readmore.itInfoCategoryId,
        });
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    };
  
    const initialize = async () => {
      await fetchCategory();
      await fetchCategories();
    };
  
    initialize();
  }, [id]);
  

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const handleCategoryChange = (value) => {
    setReadmoreData({
      ...readmoreData,
      itInfoCategoryId: value,
    });
    setSelectedCategory(value);
  };

  useEffect(() => {
    if (!selectedCategory && categories.length > 0) {
      const defaultCategory = categories[0];
      setSelectedCategory(defaultCategory.id);
      setReadmoreData((prevData) => ({
        ...prevData,
        itInfoCategoryId: defaultCategory.id,
      }));
    }
  }, [categories, selectedCategory]);

  const validateInputs = () => {
    const errors = {};

    if (!readmoreData.name) {
      errors.name = "Please enter a name";
    }

    if (!readmoreData.info) {
      errors.info = "Please enter a info";
    }

    return errors;
  };
  const handleNameChange = (e) => {
    const { value } = e.target;
    setReadmoreData((prevData) => ({
      ...prevData,
      name: value,
    }));
  };

  const handleInfoChange = (e) => {
    const { value } = e.target;
    setReadmoreData((prevData) => ({
      ...prevData,
      info: value,
    }));
  };

  const readmoreEdit = () => {
    const errors = validateInputs();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/it-info`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        id: id,
        name: readmoreData.name,
        info: readmoreData.info,
        itInfoCategoryId: readmoreData.itInfoCategoryId,
      }),
    })
      .then((res) => res.json())
      .then((resp) => {
        setReadmoreData({
          id: id,
          name: readmoreData.name,
          info: readmoreData.info,
          itInfoCategoryId: readmoreData.itInfoCategoryId,
        });
        navigate("/adminpanel/readmore")
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Read More Edit</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  onClick={readmoreEdit}
                  style={{ fontSize: "1.4rem" }}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form scroll={{ x: 400 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    placeholder="Name"
                    onChange={handleNameChange}
                    value={readmoreData.name}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.info ? "error" : ""}
                  help={errors.info}
                >
                  <TextArea
                    placeholder="Info"
                    onChange={handleInfoChange}
                    value={readmoreData.info}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.itInfoCategoryId ? "error" : ""}
                  help={errors.itInfoCategoryId}
                >
                  <Select
                    placeholder="Select a category"
                    onChange={handleCategoryChange}
                    value={readmoreData.itInfoCategoryId}
                  >
                    {categories.map((category) => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default ReadMoreEdit;
