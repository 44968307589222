import React from 'react';

const PasswordValidationBox = ({ password, show, inputRef }) => {
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const numberRegex = /[0-9]/;
  const symbolRegex = /[@#$%]/;

  const isUppercase = uppercaseRegex.test(password);
  const isLowercase = lowercaseRegex.test(password);
  const isNumber = numberRegex.test(password);
  const isSymbol = symbolRegex.test(password);

  return (
    <div ref={inputRef} className={`password-validation-box ${show ? 'show' : ''}`} >
      <p className={isUppercase ? 'valid' : 'invalid'}>One uppercase letter</p>
      <p className={isLowercase ? 'valid' : 'invalid'}>One lowercase letter</p>
      <p className={isNumber ? 'valid' : 'invalid'}>One number</p>
      <p className={isSymbol ? 'valid' : 'invalid'}>One special character(@#$%)</p>
    </div>
  );
};

export default PasswordValidationBox;
