import React, { useEffect, useState } from 'react'
import { Navigation, Pagination, Parallax } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import apiUrl from '../api/axios';
import img from ".././img/default.png"

const InternsProjects = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`${apiUrl}/site/intern-project`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        const data = response.response.data;
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  if(data.length===0) {
    return null
  }

  return (
    <div className='companyProject'>
      <div className="companyProject-con">
        <h1>Interns Project</h1>
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          speed={600}
          parallax={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Parallax, Pagination, Navigation]}
          className="mySwiper"
        >
          <div
            slot="container-start"
            className="parallax-bg"
            data-swiper-parallax="-23%"
          ></div>
          {data && data.map(a => {
            return (
              <SwiperSlide className="swiperslide" key={a.id}>
                <div className="img">
                {(!a.image || a.image.endsWith('.rar') || a.image.endsWith('.zip') || a.image.endsWith('.pdf') || a.image === null) ? (
                    <img
                      alt=""
                      src={img}
                    />
                  ) : (
                    <img
                      alt=""
                      onClick={() => window.open(`${apiUrl}/uploads/${a.image}`, '_blank')}
                      src={`${apiUrl}/uploads/${a.image}`}
                    />
                  )}
                </div>
                <div className="text" data-swiper-parallax="-300">
                  <div className="text-desc">
                    <h1>Project: {a.name ? a.name : ""} </h1>
                    <h3>Intern: {a.username ? a.username : ""} </h3>
                    <p>{a.info ? a.info : ""}</p>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default InternsProjects