import { Button, Layout, Menu, theme } from "antd";
import React, { useEffect, useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import { useNavigate, useParams } from "react-router-dom";
import { Content, Footer } from "antd/es/layout/layout";
import { getCookie } from "../../functions/token";
import AdminHeader from "./AdminHeader";
import img from "../../img/default.png";
import { sidebarItems } from "../../functions/sidebar";
import apiUrl from "../../api/axios";

const ProjectDetails = () => {
  const { id } = useParams();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [data, setData] = useState([]);
  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  useEffect(() => {
    setTableHeight(window.innerHeight);
    window.addEventListener("resize", () => { });
  }, []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  useEffect(() => {
    const fetchProjectsOfUser = () => {
      const token = getCookie("Authorization");
      fetch(`${apiUrl}/admin/project/${id}`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data.response.data);
          console.log(data.response.data);
        })
        .catch((error) => {
          console.error("Error fetching projects of user:", error);
        });
    };

    fetchProjectsOfUser();
  }, [id]);

  const MAX_LINK_LENGTH = 50;

  const truncatedLink = data.link && data.link.length > MAX_LINK_LENGTH
  ? `${data.link.substring(0, MAX_LINK_LENGTH)}...`
  : data.link;

  return (
    <Layout className="main-layout">
      <AdminHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
            display: sidebarOpen ? "block" : "none",
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ borderRight: 0 }}
            items={sidebarItems.map((item) => ({
              ...item,
              onClick: () => handleItemClick(item.href),
            }))}
          />
        </Sider>
        <Layout style={{ padding: "0 2rem 1rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBlock: "1rem",
            }}
          >
            <p style={{ fontSize: "1.6rem" }}>Project Details</p>
          </div>
          <Content
            style={{
              margin: 0,
              padding: "6rem 10rem",
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              overflowY: "auto",
            }}
          >
                    <div scroll={{ y: tableHeight - 250, x: 800 }}>
              <div
                style={{
                  display: "flex",
                  margin: "3rem 0",
                  // alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3>Project Details</h3>
                  <div style={{ width: "100%" }}>
                    <p>
                      <span
                        style={{
                          fontSize: "1.6rem",
                          fontWeight: "bold",
                          marginTop: 15,
                        }}
                      >
                        Name:
                      </span>{" "}
                      {data.name}
                    </p>
                    <p>
                      <span
                        style={{
                          fontSize: "1.6rem",
                          fontWeight: "bold",
                          marginTop: 15,
                        }}
                      >
                        Description:
                      </span>{" "}
                      {data.description}
                    </p>
                  </div>
                  <p>
                    <span
                      style={{
                        fontSize: "1.6rem",
                        fontWeight: "bold",
                        marginTop: 15,
                      }}
                    >
                      Link:
                    </span>{" "}
                    <a
                      href={data.link}
                      target="_blank"
                      style={{
                        textDecoration: "underline",
                        marginBottom: "2rem",
                        fontSize: "1.4rem"
                      }}
                    >
                      {truncatedLink}
                    </a>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "1.6rem",
                        fontWeight: "bold",
                        marginTop: 15,
                      }}
                    >
                      Mark:
                    </span>{" "}
                    {data.mark}
                  </p>
                  <Button
                    type="primary"
                    shape="round"
                    icon={<DownloadOutlined />}
                    style={{ marginTop: "2rem" }}
                  >
                    <a
                      href={`${apiUrl}/uploads/${data.file}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Download
                    </a>
                  </Button>
                </div>
                <div style={{ marginLeft: 50 }}>
                  <h3>Task Details</h3>
                  <div style={{ width: "100%" }}>
                    <p>
                      <span
                        style={{
                          fontSize: "1.6rem",
                          fontWeight: "bold",
                          marginTop: 15,
                        }}
                      >
                        Name:
                      </span>{" "}
                      {data.taskName}
                    </p>
                    <p>
                      <span
                        style={{
                          fontSize: "1.6rem",
                          fontWeight: "bold",
                          marginTop: 15,
                        }}
                      >
                        Description:
                      </span>{" "}
                      {data.taskDescription}
                    </p>
                    <p>
                      <span
                        style={{
                          fontSize: "1.6rem",
                          fontWeight: "bold",
                          marginTop: 15,
                        }}
                      >
                        Category:
                      </span>{" "}
                      {data.categoryName}
                    </p>
                    <Button
                      type="primary"
                      shape="round"
                      icon={<DownloadOutlined />}
                      style={{ marginTop: "2rem" }}
                    >
                      <a
                        href={`${apiUrl}/uploads/${data.taskFile}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        Download
                      </a>
                    </Button>
                  </div>
                </div>
              </div>            
            </div>
          </Content>
          <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
            Created by NSP SOLUTIONS ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ProjectDetails;
