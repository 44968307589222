import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Form,
  Input,
  Layout,
  Menu,
  Select,
  message,
  theme,
} from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const TaskAdd = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const [categoryId, setcategoryId] = useState("");
  const [task, setTask] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [errors, setErrors] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const token = getCookie("Authorization");
      try {
        const response = await fetch(`${apiUrl}/profile/category`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${token}`,
          },
        })
          .then((res) => {
            if (!res.ok) {
              messageFunc("error", "Network response was not ok");
            }
            return res.json();
          })
          .then((data) => {
            setTask(data.response.data);
          });
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
    }
  };
  const validateInputs = () => {
    const errors = {};

    if (!name) {
      errors.name = "Please enter a Name";
    }

    if (!description) {
      errors.description = "Please enter a Description";
    }

    if (!categoryId) {
      errors.categoryId = "Please enter a Category";
    }

    if (!file) {
      errors.file = "Please select a File";
    }

    return errors;
  };

  const addTask = async () => {
    try {
      const errors = validateInputs();
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      let uploadedFile = "";
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const token = getCookie("Authorization");
        const uploadResponse = await fetch(`${apiUrl}/upload`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: formData,
        });
        if (!uploadResponse.ok) {
          messageFunc("error", "Failed to upload file");
          return;
        }
        const uploadData = await uploadResponse.json();
        uploadedFile = uploadData.response.data;
        task.file = uploadedFile;
      }
      const token = getCookie("Authorization");
      const requestData = {
        name: name,
        description: description,
        file: task.file,
        categoryId: categoryId,
      };
      fetch(`${apiUrl}/admin/task`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(requestData),
      })
        .then((res) => {
          if (!res.ok) {
            messageFunc("error", "Network response was not ok");
          }
          return res.json();
        })
        .then((data) => {
          setTask([...task, data]);
          setName("");
          setDescription("");
          setFile("");
          setcategoryId("");
          navigate("/adminpanel/task");
        });
    } catch (error) {
      console.error("Error updating:", error);
    }
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Task Add</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  onClick={addTask}
                  style={{ fontSize: "1.4rem" }}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form scroll={{ x: 800 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    placeholder="Name"
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.description ? "error" : ""}
                  help={errors.description}
                >
                  <Input
                    placeholder="Description"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.categoryId ? "error" : ""}
                  help={errors.categoryId}
                >
                  <Select
                    placeholder="Select a category"
                    onChange={(value) => setcategoryId(value)}
                  >
                    {task.map((category) => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  validateStatus={errors.file ? "error" : ""}
                  help={errors.file}
                >File:
                  <input
                    type="file"
                    id="file"
                    onChange={handleFileChange}
                    style={{ display: "inline",marginLeft:"1rem" }}
                  />
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default TaskAdd;
