import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiUrl from '../api/axios';
import { message } from 'antd';

const OTP = ({ username, name, surname, password, confirmPassword }) => {
  const [otpCode, setOtpCode] = useState('');
  const [resendTimer, setResendTimer] = useState();
  const [otpValidityTime, setOtpValidityTime] = useState(0);
  const [canResend, setCanResend] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${apiUrl}/site/otp-time`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setOtpValidityTime(data.response.data.otpTime);
        setResendTimer(data.response.data.otpTime);
      })
      .catch((error) => {
        messageFunc('error', 'Failed to fetch OTP validity time: ' + error.message);
      });
  }, []);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCanResend(true);
      setOtpCode('');
    }
  }, [resendTimer]);

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    if (!otpCode) {
      messageFunc('error', 'Please enter the OTP code.');
      return;
    }
    const userData = {
      name,
      surname,
      username,
      password,
      confirmPassword,
      otpCode,
    };

    fetch(`${apiUrl}/auth/otp-code-register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 201) {
          messageFunc('success', 'OTP verified successfully! Redirecting to login.');
          setTimeout(() => {
            navigate('/login');
          }, 1500);
        } else {
          messageFunc('error', 'Incorrect OTP!' || 'Unknown error');
        }
      })
      .catch((error) => {
        messageFunc('error', 'An error occurred: ' + error.message);
      });
  };

  const handleResendOTP = (e) => {
    // setCanResend(false);
    setResendTimer(otpValidityTime);

    e.preventDefault();
    const userData = {
      name,
      surname,
      username,
      password,
      confirmPassword,
      otpCode,
    };

    fetch(`${apiUrl}/auth/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          messageFunc('success', 'OTP sent successfully!');
        } else {
          messageFunc('error', 'Incorrect OTP!' || 'Unknown error');
        }
      })
      .catch((error) => {
        messageFunc('error', 'An error occurred: ' + error.message);
      });

  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <>
      {contextHolder}
      <div className="otp">
        <div className="form">
          <div className="form-con-otp">
            <h1>Enter OTP Code</h1>
            <form onSubmit={handleVerifyOTP}>
              <p>An OTP code has been sent to your email. Please enter it below:</p>
              <input
                id="otpCodeInput"
                type="text"
                name="otpCode"
                placeholder="OTP Code"
                value={otpCode}
                onChange={(e) => setOtpCode(e.target.value)}
              />
              <button type="submit">Verify OTP</button>
            </form>
            <p>
              Didn't receive the OTP code?{' '}
              <button
                onClick={handleResendOTP}
                // disabled={!canResend}
                style={{ border: 'none', background: 'none', color: 'blue', cursor: canResend ? 'pointer' : 'not-allowed' }}
              >
                Resend OTP {resendTimer > 0 && `(${formatTime(resendTimer)})`}
              </button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OTP;
