import React, { useEffect, useState } from "react";
import { Flex, Form, Select, message } from "antd";
import { Button, Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import { Option } from "antd/es/mentions";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const TaskUserAdd = () => {
  const [selectedTask, setSelectedTask] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState([]);
  const [taskData, settaskData] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  useEffect(() => {
    const fetchTaskUserData = async () => {
      try {
        const token = getCookie("Authorization");
        const userResponse = await fetch(`${apiUrl}/admin/user`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token,
          },
        });
        if (!userResponse.ok) {
          messageFunc("error", "Network response was not ok");
        }
        const userData = await userResponse.json();
        setUserData(
          userData.response.data.map((user) => ({
            userId: user.id,
            username: user.username,
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };


    const fetchTaskData = async () => {
      try {
        const token = getCookie("Authorization");
        const taskResponse = await fetch(`${apiUrl}/admin/task`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token,
          },
        });
        if (!taskResponse.ok) {
          messageFunc("error", "Network response was not ok");
        }
        const taskData = await taskResponse.json();
        settaskData(
          taskData.response.data.map((task) => ({
            taskId: task.id,
            taskName: task.name,
          }))
        );
      } catch (error) {
        messageFunc("error", "Error fetching task data:", error);
      }
    };

    Promise.all([fetchTaskUserData(), fetchTaskData()]).catch((error) => {
      messageFunc("error", "Error fetching task and user data:", error);
    });
  }, []);

  const validateInputs = () => {
    const errors = {};

    if (selectedUser.length === 0) {
      errors.username = "Please select a User";
    }

    if (selectedTask.length === 0) {
      errors.taskName = "Please select a Task";
    }

    return errors;
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const handleSave = async () => {
    try {
      const errors = validateInputs();
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      const token = getCookie("Authorization");
      for (let j = 0; j < selectedUser.length; j++) {
        const userId = selectedUser[j];
        const promises = selectedTask.map(async (taskId) => {
          const response = await fetch(`${apiUrl}/admin/task-user`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              taskId,
              userId,
            }),
          });

          if (!response.ok) {
            messageFunc(
              "error",
              "The task and user option you entered is available"
            );
          } else {
            const responseData = await response.json();
            setTimeout(() => {
              navigate("/adminpanel/taskUser");
            }, 1500);
            return responseData;
          }
        });

        await Promise.all(promises);
      }
    } catch (error) {
      console.error("Error adding task user:", error);
    }
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>TaskUser Add</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  onClick={handleSave}
                  style={{ fontSize: "1.4rem" }}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                margin: 0,
                minHeight: 280,
                padding: "24px",
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form scroll={{ x: 800 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.taskName ? "error" : ""}
                  help={errors.taskName}
                >
                  <Select
                    placeholder="Select a task"
                    mode="multiple"
                    style={{ width: "100%" }}
                    value={selectedTask}
                    onChange={(value) => setSelectedTask(value)}
                  >
                    {taskData.map((task) => (
                      <Option key={task.taskId} value={task.taskId}>
                        {task.taskName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  validateStatus={errors.username ? "error" : ""}
                  help={errors.username}
                >
                  <Select
                    placeholder="Select a user"
                    mode="multiple"
                    style={{ width: "100%" }}
                    value={selectedUser}
                    onChange={(value) => setSelectedUser(value)}
                  >
                    {userData.map((user) => (
                      <Option key={user.userId} value={user.userId}>
                        {user.username}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default TaskUserAdd;
