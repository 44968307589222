import React, { useCallback, useEffect, useRef, useState } from "react";
import { Space, Table, Flex, Modal, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import { Footer } from "antd/es/layout/layout";
import AdminHeader from "./AdminHeader";
import { getColumnSearchProps } from "../../functions/search";
import { sidebarItems } from "../../functions/sidebar";

const { Content, Sider } = Layout;

const { Column } = Table;

const Category = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = useCallback((typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  }, [messageApi]);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (index, href) => {
    navigate(href);
    if (selectedItem === null) {
      setSelectedItem(index);
    } else {
      setSelectedItem(null);
    }
  };
  const [categoryData, setCategoryData] = useState();
  
  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/profile/category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data && data.response && data.response.data) {
          const sortedData = data.response.data.sort((a, b) => a.id - b.id);
          setCategoryData(sortedData);
        } else {
          messageFunc("error", "Error fetching data: Empty response");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [messageFunc]);

  const handleDelete = (id) => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/category/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc(
            "error",
            "This category cannot be deleted because it is in use!"
          );
        } else {
          return res.json();
        }
      })
      .then((resp) => {
        if (resp.status === 200) {
          setCategoryData(categoryData.filter((resp) => resp.id !== id));
        }
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const showModal = (id) => {
    setIsModalVisible(true);
    setDeleteItemId(id);
  };

  const handleOk = () => {
    handleDelete(deleteItemId);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [tableHeight, setTableHeight] = useState(window.innerHeight);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setTableHeight(window.innerHeight);
    });
  }, []);

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item, index) => ({
                ...item,
                onClick: () => handleItemClick(index, item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Category</p>
              <Flex justify="flex-end" align="center">
                <Button
                  style={{ marginRight: "1.5rem", fontSize: "1.4rem" }}
                  href="/adminPanel/categoryAdd"
                >
                  Add
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Table
                dataSource={categoryData}
                bordered
                pagination={false}
                scroll={{ y: tableHeight - 250, x: 400 }}
              >
                <Column
                  title="No"
                  key="index"
                  width={60}
                  className="center-align"
                  render={(text, record, index) => <span>{index + 1}</span>}
                />
                <Column
                  title="Name"
                  dataIndex="name"
                  key="name"
                  {...getColumnSearchProps(
                    "name",
                    searchText,
                    setSearchText,
                    searchedColumn,
                    setSearchedColumn,
                    searchInput
                  )}
                />
                <Column
                  title="Actions"
                  key="actions"
                  width={150}
                  render={(_, record) => (
                    <Space size="middle">
                      <Button
                        style={{ color: "blue" }}
                        href={`/adminpanel/categoryedit/${record.id}`}
                      >
                        <EditOutlined />
                      </Button>
                      <div>
                        <Button
                          key="buton"
                          danger
                          style={{ color: "red" }}
                          onClick={() => {
                            showModal(record.id);
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                        <Modal
                          key={`modal-${record.id}`}
                          title="Delete"
                          open={isModalVisible}
                          onOk={handleOk}
                          onCancel={handleCancel}
                        >
                          <p>Are you sure you want to delete?</p>
                        </Modal>
                      </div>
                    </Space>
                  )}
                />
              </Table>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default Category;
