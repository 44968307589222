import home from '../img/home.png'
import React, { useEffect, useState } from "react"
import { NavHashLink } from "react-router-hash-link";
import apiUrl from '../api/axios';

const Home = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/site/about-us`);
        const jsonData = await response.json();
        setData(jsonData.response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  const [hasProject, setHasProject] = useState([]);
  useEffect(() => {
    fetch(`${apiUrl}/site/company-project`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        const data = response.response.data;
        setHasProject(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="home-wrapper" id='home'>
      <section className='home'>
        <div className="home-con">
          <h3>CREATIVE & MINIMAL IT AGENCY.</h3>
          <p>{data.pageInfo ? data.pageInfo : ""}</p>
          <div className="buttons">
            <NavHashLink to={'#about'}><button className='btn-about'>About Us</button></NavHashLink>
            {hasProject.length !== 0 && (
              <NavHashLink to={'#company'}><button className='btn-project'>See Project</button></NavHashLink>
            )}
          </div>
        </div>
        <div className="home-img">
          <img src={home} alt="" />
        </div>
      </section>
    </div>
  )
}

export default Home
