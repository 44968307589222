import React, { useEffect, useState } from "react";
import { Avatar, Button, Dropdown, Menu } from "antd";
import {
  UserOutlined, LogoutOutlined, MenuOutlined, CloseOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { clearToken, getCookie } from "../functions/token";
import apiUrl from "../api/axios";
import defaultImg from "../img/ppdefault.jpg"

const Navbar = () => {
  const [hasEducation, setHasEducation] = useState([]);
  const [hasProject, setHasProject] = useState([]);
  const [hasIntern, setHasIntern] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [userRole, setUserRole] = useState([]);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${apiUrl}/site/company-project`);
      const result = await res.json();
      setHasProject(result.response.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${apiUrl}/site/intern-project`);
      const result = await res.json();
      setHasIntern(result.response.data);
    };
    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${apiUrl}/site/course`);
      const result = await res.json();
      setHasEducation(result.response.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/site/about-us`);
        const jsonData = await response.json();
        setData(jsonData.response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const token = getCookie("Authorization");
    if (token) {
      setUserData(token);
    } else {
      setUserData(null);
    }
  }, []);


  useEffect(() => {
    const token = getCookie('Authorization');
    if (token !== null) {
      fetch(`${apiUrl}/profile/info`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `${token}`,
        },
      })
        .then((res) => res.json())
        .then((userData) => {
          const user = userData.response.data;
          setUserData(user)
        })
        .catch((error) => {
          throw new Error('Error fetching user data:', error);
        });
    }
  }, []);

  useEffect(() => {
    const token = getCookie("Authorization");
    if (token !== null) {
      fetch(`${apiUrl}/profile/get-role`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`
        }
      })
        .then((res) => res.json())
        .then((data) => {
          const role = data.response.data;
          setUserRole(role);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, []);


  const handleScrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      const headerOffset = 70;
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };


  const handleMenuClick = ({ key }) => {
    if (key === 'logout') {
      clearToken()
      setTimeout(() => {
        window.location.reload();
      }, 1);
    }
    if (key === 'profile') {
      navigate("/profile")
    }
    if (key === 'adminpanel') {
      navigate("/adminpanel/about")
    }
    setOpen(false);
  };

  return (
    <div className="wrapper">
      <nav>
        <div className="logo">
          <img
            alt="logo"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.location.reload();
            }}
            src={(data && data.icon) ?
              (data.icon.startsWith('http://') || data.icon.startsWith('https://')) ?
                data.icon : `${apiUrl}/uploads/${data.icon}` :
              data.icon}
          />
        </div>
        <div className="menu">
          <ul className="menu-ul">
            <li>
              <a href="#home" onClick={(e) => handleScrollToSection(e, 'home')}>Home</a>
            </li>
            <li>
              <a href="#about" onClick={(e) => handleScrollToSection(e, 'about')}>About Us</a>
            </li>
            {hasEducation.length !== 0 && (
              <li>
                <a href="#education" onClick={(e) => handleScrollToSection(e, 'education')}>Steps of Education</a>
              </li>)}
            {(hasProject.length !== 0 || hasIntern.length !== 0) && (
              <li className="submenu">
                <a href="#company" onClick={(e) => handleScrollToSection(e, 'company')}>Project</a>
              </li>
            )}
            <li>
              <a href="#contact" onClick={(e) => handleScrollToSection(e, 'contact')}>Contact</a>
            </li>
          </ul>
          {userData ? (
            <>
                <li className="username" style={{fontSize: 13, marginLeft: 80}}>{userData.name + " " + userData.surname}</li>
                <Dropdown
                  key="userDropdown"
                  trigger={['click']}
                  open={open}
                  onOpenChange={(flag) => setOpen(flag)}
                  placement="topRight"
                  overlayStyle={{ zIndex: 9999 }}
                  overlay={
                    <Menu onClick={handleMenuClick}>
                      <Menu.Item key="profile">Profile</Menu.Item>
                      {userRole === "ADMIN" && (
                        <Menu.Item key="adminpanel">Admin Panel</Menu.Item>
                      )}
                      <Menu.Item key="logout" style={{ color: "red" }} icon={<LogoutOutlined />}>
                        Logout
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Avatar
                    className="avatar"
                    size={50}
                    icon={<UserOutlined />}
                    style={{ border: "1px solid #fff", cursor: 'pointer' }}
                    src={userData.image ? `${apiUrl}/uploads/${userData.image}` : defaultImg}
                    onClick={() => setOpen(!open)}
                  />
                  
                </Dropdown>
            </>
          ) : (
            <div style={{ display: "flex" }}>
              <Button
                className="button"
                onClick={() => {
                  navigate("/login");
                }}
                type="primary"
                style={{ marginRight: "1rem" }}
              >
                Sign in
              </Button>
              <Button
                className="button"
                onClick={() => {
                  navigate("/register");
                }}
              >
                Sign Up
              </Button>
            </div>
          )}
        </div>

        {!userData && (
          <div className="mobile-menu-icon" onClick={toggleMenu}>
            {isMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
          </div>
        )}

        {isMenuOpen && (
          <ul className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
            {userData ? (
              setIsMenuOpen(false)
            ) : (
              <>
                <li>
                  <a href="/login"
                    style={{ marginRight: "1rem" }}
                  >
                    Sign in
                  </a>
                </li>
                <li>
                  <a href="/register">
                    Sign Up
                  </a>
                </li>
              </>
            )}
          </ul>
        )}

      </nav>
    </div>
  );
};

export default Navbar;
