import { useState, useEffect } from "react";
import man from "../img/man.png";
import React from 'react';
import apiUrl from "../api/axios";
import ResetPassword from "./ResetPassword";
import { message } from "antd";

const ForgotPassword = () => {
    const [username, setUsername] = useState("");
    const [otpCode, setOtp] = useState("");
    const [showOtpField, setShowOtpField] = useState(false);
    const [verifiedOtp, setVerifiedOtp] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);
    const [canResend, setCanResend] = useState();
    const [otpValidityTime, setOtpValidityTime] = useState(0);
    const [messageApi, contextHolder] = message.useMessage();

    const messageFunc = (typeMessage, text) => {
        messageApi.open({
            type: typeMessage,
            content: text,
        });
    };

    useEffect(() => {
        fetch(`${apiUrl}/site/otp-time`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        })
        .then((res) => res.json())
        .then((data) => {
            setOtpValidityTime(data.response.data.otpTime);
            setResendTimer(data.response.data.otpTime);
        })
        .catch((error) => {
            messageFunc('error', 'Failed to fetch OTP validity time: ' + error.message);
        });
    }, []);

    useEffect(() => {
        if (resendTimer > 0) {
            const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            // setCanResend(true);
        }
    }, [resendTimer]);

    const handleSendOtp = async (e) => {
        e.preventDefault();
        if (!username) {
            messageFunc("error", 'Please enter your username.');
            return;
        }
        try {
            const response = await fetch(`${apiUrl}/auth/forget-password`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ username }),
            });
            const data = await response.json();
            if (response.status === 200) {
                setShowOtpField(true);
                setResendTimer(otpValidityTime);
                setCanResend(false);
                messageFunc("success", 'OTP sent successfully.');
            } else {
                messageFunc("error", data.message || 'Failed to send OTP.');
            }
        } catch (error) {
            messageFunc("error", 'An error occurred while sending OTP.');
        }
    };

    const handleVerifyOtp = (e) => {
        e.preventDefault();
        if (!otpCode) {
            messageFunc("error", 'Please enter the OTP code.');
            return;
        }
        fetch(`${apiUrl}/auth/otp-code`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ username, otpCode }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 200) {
                    setVerifiedOtp(true);
                    messageFunc("success", 'OTP verified successfully.');
                } else {
                    setVerifiedOtp(false);
                    messageFunc("error", 'Incorrect OTP.');
                }
            })
            .catch(error => {
                setVerifiedOtp(false);
                messageFunc("error", 'An error occurred while verifying OTP.');
            });
    };

    const handleResendOtp = async (e) => {
        e.preventDefault();
        if (canResend) {
            try {
                const response = await fetch(`${apiUrl}/auth/forget-password`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify({ username, otpCode }),
                });
                const data = await response.json();
                if (response.status === 200) {
                    setResendTimer(otpValidityTime);
                    // setCanResend(false);
                    messageFunc("success", 'OTP resent successfully.');
                } else {
                    messageFunc("error", data.message || 'Failed to resend OTP.');
                }
            } catch (error) {
                messageFunc("error", 'An error occurred while resending OTP.');
            }
        }
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <>
            {contextHolder}
            <div className="register">
                <div className="image">
                    <img src={man} alt="" />
                </div>
                <div className="form">
                    <div className="form-con-register">
                        {!verifiedOtp ? (
                            <>
                                <form onSubmit={showOtpField ? handleVerifyOtp : handleSendOtp}>
                                    <h1 style={{ textAlign: "center" }}>Forgot Password</h1>
                                    <input
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        name="username"
                                        placeholder="Username"
                                    />
                                    {showOtpField && (
                                        <>
                                            <input
                                                value={otpCode}
                                                onChange={(e) => setOtp(e.target.value)}
                                                name="otpCode"
                                                placeholder="OTP"
                                            />
                                            <button onClick={handleResendOtp} disabled={!canResend} style={{ border: 'none', background: 'none', color: 'blue', cursor: canResend ? 'pointer' : 'not-allowed' }}>
                                                Resend OTP {resendTimer > 0 && `(${formatTime(resendTimer)})`}
                                            </button>
                                        </>
                                    )}
                                    <button type="submit">{showOtpField ? 'Verify OTP' : 'Send OTP'}</button>
                                </form>
                            </>
                        ) : (
                            <ResetPassword username={username} otpCode={otpCode} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
