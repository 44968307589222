import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Layout, Menu, Flex, theme, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import apiUrl from "../../api/axios";
import { getCookie } from "../../functions/token";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const CompanyEdit = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const { id } = useParams();
  const [companyData, setCompanyData] = useState({
    id: id,
    image: "",
    name: "",
    info: "",
    username: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const token = getCookie("Authorization");
        const response = await fetch(`${apiUrl}/site/intern-project/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${token}`,
          },
        });
        if (!response.ok) {
          messageFunc("error", "Failed to fetch intern project data");
        }
        const companyData = await response.json();
        const company = companyData.response.data;
        setCompanyData({
          id: id,
          image: company.image,
          name: company.name,
          info: company.info,
          username: company.username,
        });
      } catch (error) {
        console.error("Error fetching intern project data:", error);
      }
    };
    fetchCompanyData();
  }, [id]);

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [errors, setErrors] = useState({});
  const validateInputs = () => {
    const errors = {};

    if (!companyData.name) {
      errors.name = "Please fill in Name";
    }
    if (!companyData.info) {
      errors.info = "Please fill in Description";
    }
    if (!companyData.username) {
      errors.username = "Please fill in Username";
    }

    return errors;
  };

  const handleSave = async () => {
    const errors = validateInputs();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    try {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const token = getCookie("Authorization");
        const uploadResponse = await fetch(`${apiUrl}/upload`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: formData,
        });
        if (!uploadResponse.ok) {
          messageFunc("error", "Failed to upload file");
        }
        const uploadData = await uploadResponse.json();
        const uploadedFile = uploadData.response.data;
        companyData.image = uploadedFile;
      }
      const token = getCookie("Authorization");
      const response = await fetch(`${apiUrl}/admin/intern-project`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: id,
          image: companyData.image,
          username: companyData.username,
          name: companyData.name,
          info: companyData.info,
        }),
      });
      if (!response.ok) {
        messageFunc("error", "Failed to update company data");
      }
      const responseData = await response.json();
      setCompanyData({
        id: responseData.id,
        image: responseData.image,
        name: responseData.name,
        username: responseData.username,
        info: responseData.info,
      });
      if (responseData.status === 200) {
        navigate("/adminpanel/internproject");
      } else {
        messageFunc("error", "Failed to update intern project data");
      }
    } catch (error) {
      console.error("Error updating intern project data:", error);
    }
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Intern Project Edit</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  onClick={handleSave}
                  style={{ fontSize: "1.4rem" }}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                overflowY: "auto",
                borderRadius: borderRadiusLG,
              }}
            >
              <Form scroll={{ x: 400 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    placeholder="Project Name"
                    name="name"
                    value={companyData.name}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.info ? "error" : ""}
                  help={errors.info}
                >
                  <Input
                    placeholder="Description"
                    name="info"
                    value={companyData.info}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors.username ? "error" : ""}
                  help={errors.username}
                >
                  <Input
                    placeholder="Username"
                    name="username"
                    value={companyData.username}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item label="Image" valuePropName="file">
                  <input type="file" id="file" onChange={handleFileChange} accept="image/*"/>
                </Form.Item>
                {companyData.image && companyData.image !== null && (
                  <div className="image-icon-overlay">
                    <>
                      <img
                        src={`${apiUrl}/uploads/${companyData.image}`}
                        alt=""
                        name="fileName"
                        className="cv-image"        
                      />
                    </>
                  </div>
                )}
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default CompanyEdit;
