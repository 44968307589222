import React, { useEffect, useState } from "react";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Avatar, Menu, Layout, Dropdown } from "antd";
import apiUrl from "../../api/axios";
import { clearToken, getCookie } from "../../functions/token";
import { useNavigate } from "react-router-dom";
import defaultImg from "../../img/ppdefault.jpg";
const { Header } = Layout;

const AdminHeader = ({ sidebarOpen, setSidebarOpen }) => {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/profile/info`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((userData) => {
        const user = userData.response.data;
        setUserData(user);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const [open, setOpen] = useState(false);

  const handleMenuClick = ({ key }) => {
    if (key === "logout") {
      clearToken();
      navigate("/");
    }
    if (key === "profile") {
      navigate("/profile");
    }
    setOpen(false);
  };

  const handleProfileClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="logout"
        style={{ color: "red" }}
        icon={<LogoutOutlined />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ display: "flex", alignItems: "center" }}>
      <div className="demo-logo" />
      <a href="/" style={{ color: "#fff", padding: "0rem 1rem", fontSize: "1.3rem" }}>
        Home
      </a>
      <a
        href="/profile"
        style={{ color: "#fff", margin: "0 2rem", padding: "0rem 1rem", fontSize: "1.3rem" }}
      >
        Profile
      </a>
      <a
      className="admin-text"
        type="link"
        style={{
          color: "#fff",
          backgroundColor: "#1677FF",
          padding: "0rem 1rem",
          borderRadius: "0",
          fontSize: "1.3rem"
        }}
        href="/adminpanel/about"
      >
        Admin Panel
      </a>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={["2"]}
        style={{ flex: 1, minWidth: 0, fontSize: "14px" }}
      />
      <li className="adminpanel-username" style={{ fontSize: 13, marginRight: 20, color: "whitesmoke"}}>{userData.name + " " + userData.surname}</li>
      <Dropdown
        overlay={menu}
        key="userDropdown"
        trigger={["click"]}
        open={open}
        onOpenChange={(flag) => setOpen(flag)}
      >
        <Avatar
          size="large"
          icon={<UserOutlined />}
          style={{ border: "1px solid #fff", cursor: "pointer" }}
          src={
            userData.image ? `${apiUrl}/uploads/${userData.image}` : defaultImg
          }
          onClick={() => setOpen(!open)}
        />
      </Dropdown>
    </Header>
  );
};

export default AdminHeader;
