import React, { useEffect, useRef, useState } from "react";
import { Input, Modal, Space, Table, message } from "antd";
import {
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import apiUrl from "../../api/axios";
import { getCookie } from "../../functions/token";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import { sidebarItems } from "../../functions/sidebar";
import { getColumnSearchProps } from "../../functions/search";
const { Content, Sider } = Layout;

const UserProject = () => {
  const { id } = useParams();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [projectData, setProjectData] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/projects-of-user/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((projectData) => {
        const project = projectData.response.data;
        setProjectData(project);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);


  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "No",
        dataIndex: "index",
        key: "index",
        width: 60,
        render: (_, __, index) => index + 1,
        className: "center-align",
      },
      { title: "Task Name", dataIndex: "taskName", key: "taskName" },
      { title: "Task Description", 
      dataIndex: "taskDescription", 
      key: "taskDescription", 
      ellipsis: {showTitle: true},    
      ...getColumnSearchProps("taskDescription", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput),
    },
      {
        title: "Task File",
        dataIndex: "taskFile",
        key: "taskFile",
        render: (fileSrc) => {
          return (
            <a
              href={`${apiUrl}/uploads/${fileSrc}`}
              download
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                marginBottom: "2rem",
                fontSize: "1.2rem"
              }}
            >
              Download task file↗
            </a>
          );
        },
      },
    ];
    return <Table columns={columns} dataSource={[record]} pagination={false} />;
  };

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
      width: 60,
      render: (_, __, index) => index + 1,
      className: "center-align",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput),
      ellipsis: {showTitle: true}
    },
    {
      title: "IsDeleted",
      dataIndex: "isDeleted",
      key: "isDeleted",
      width: 100,
      render: (isDeleted) => (isDeleted === 1 ? "true" : "false"),
      ellipsis: { showTitle: true },
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      ellipsis: {showTitle: true},
      ...getColumnSearchProps("link", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput),
      render: (link, record) => (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}{" "}
        </a>
      )
    },
    {
      title: "Mark",
      dataIndex: "mark",
      key: "mark",
      render: (text, record) => (
        <Space
          size="small"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {text}
          <Button size="small" onClick={() => handleOpenModal(record.key)}>
            Set
          </Button>
        </Space>
      ),
    },
    {
      title: "Task",
      dataIndex: "taskName",
      key: "taskName",
      ellipsis: {showTitle: true},
      ...getColumnSearchProps("taskName", searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      render: (fileSrc) => (
        // <img
        //   alt=""
        //   onClick={() => window.open(`${apiUrl}/uploads/${fileSrc}`, "_blank")}
        //   src={`${apiUrl}/uploads/${fileSrc}`}
        //   style={{height: "80px" }}
        // />
        <a href={`${apiUrl}/uploads/${fileSrc}`} target="_blank" rel="noreferrer" download style={{textDecoration:"underline"}}>Download file↗</a>
      ),
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      width: 100,
      render: (_, record) => (
        <Space size="middle">
 
          <div>
            <Button
              key="buton"
              danger
              style={{ color: "red" }}
              onClick={() => {
                showModal(record.key);
              }}
            >
              <DeleteOutlined />
            </Button>
            <Modal
              key={`modal-${record.id}`}
              title="Delete"
              open={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <p>Are you sure you want to delete?</p>
            </Modal>
          </div>
        </Space>
      ),
    },
  ];
  const data = projectData.map((project, index) => ({
    key: project.id,
    name: project.name,
    description: project.description,
    link: project.link,
    mark: project.mark,
    task: project.task,
    taskName: project.taskName,
    taskDescription: project.taskDescription,
    taskFile: project.taskFile,
    categoryName: project.categoryName,
    username: project.username,
    file: project.file,
    isDeleted: project.isDeleted,  }));

  const handleDelete = (id) => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/project/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        setProjectData(projectData.filter((resp) => resp.id !== id));
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const showModal = (id) => {
    setIsModalVisible(true);
    setDeleteItemId(id);
  };

  const handleOk = () => {
    handleDelete(deleteItemId);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setTableHeight(window.innerHeight);
    });
  }, []);

  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [recordKey, setRecordKey] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };
  
  const handleOpenModal = (key) => {
    setRecordKey(key);
    setVisible(true);
    setInputValue("");
  };

  const handleOkModal = () => {
    const value = parseInt(inputValue, 10);
    if (isNaN(value) || value < 0 || value > 100) {
      messageFunc("warning", "Please enter a number between 1 and 100.");
      return;
    }
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/admin/set-mark`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        id: recordKey,
        mark: value,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          console.error("Failed to set mark");
          return;
        }
        return response.json();
      })
      .then((data) => {
        setVisible(false);
        const updatedData = projectData.map((project) => {
          if (project.id === recordKey) {
            console.log(project.id);
            return {
              ...project,
              mark: value,
            };
          }
          return project;
        });
        setProjectData(updatedData);
      })
      .catch((error) => {
        console.error("Error setting mark:", error);
      });
  };

  const handleCancelModal = () => {
    setVisible(false);
  };

  return (
    <>
    {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>User Project</p>
            </div>
            <Content
              style={{
                overflowY: "auto",
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Modal
                title="Set Mark"
                open={visible}
                onOk={handleOkModal}
                onCancel={handleCancelModal}
              >
                <Input
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Please enter a number between 1 and 100."
                  type="number"
                />
              </Modal>
              
              <Table
                columns={columns}
                dataSource={data}
                expandable={{
                  expandedRowRender: (record) => expandedRowRender(record),
                  defaultExpandedRowKeys: ["0"],
                }}
                bordered
                pagination={false}
                scroll={{ y: tableHeight - 250, x: 1000 }}
                className="sub-layout"
                rowClassName={(record) => (record.isDeleted === 1 ? "gray-row" : "")}
              />
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default UserProject;
