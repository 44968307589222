import { Button, Layout, Menu, theme } from "antd";
import React, { useEffect, useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import { useNavigate, useParams } from "react-router-dom";
import { Content, Footer } from "antd/es/layout/layout";
import apiUrl from "../api/axios";
import { getCookie } from "../functions/token";
import MenuHeader from "./MenuHeader";
import img from "../img/default.png";
import { sidebarItem } from "../functions/sidebar";

const TaskDetails = () => {
  const { id } = useParams();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [data, setData] = useState([]);
  const [tableHeight, setTableHeight] = useState(window.innerHeight);

  useEffect(() => {
    setTableHeight(window.innerHeight);
    window.addEventListener("resize", () => {});
  }, []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  useEffect(() => {
    const fetchProjectsOfUser = async () => {
      try {
        const token = getCookie("Authorization");
        const response = await fetch(`${apiUrl}/profile/task/${id}`, {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setData(data.response.data);
        } else {
          return null;
        }
      } catch (error) {
        console.error("Error fetching projects of user:", error);
      }
    };

    fetchProjectsOfUser();
  }, [id]);

  return (
    <Layout className="main-layout">
      <MenuHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
            display: sidebarOpen ? "block" : "none",
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ borderRight: 0 }}
            items={sidebarItem.map((item) => ({
              ...item,
              onClick: () => handleItemClick(item.href),
            }))}
          />
        </Sider>
        <Layout style={{ padding: "0 2rem 1rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBlock: "1rem",
            }}
          >
            <p style={{ fontSize: "1.9rem" }}>Task Details</p>
          </div>
          <Content
            style={{
              margin: 0,
              padding: "6rem 10rem",
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              overflowY: "auto",
            }}
          >
            <div scroll={{ y: tableHeight - 250, x: 800 }}>
              <div
                style={{
                  display: "flex",
                  margin: "3rem 0",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p>
                    <span
                      style={{
                        fontSize: "1.6rem",
                        fontWeight: "bold",
                        marginTop: 15,
                      }}
                    >
                      Name:
                    </span>{" "}
                    {data.name ? data.name : ""}
                  </p>
                  <div style={{ width: "60%" }}>
                    <p>
                      <span
                        style={{
                          fontSize: "1.6rem",
                          fontWeight: "bold",
                          marginTop: 15,
                        }}
                      >
                        Description:
                      </span>{" "}
                      {data.description ? data.description : ""}
                    </p>
                  </div>
                </div>
              </div>
              <Button
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
                style={{ marginTop: "1rem" }}
              >
                <a
                  href={`${apiUrl}/uploads/${data.file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  Download
                </a>
              </Button>
            </div>
          </Content>
          <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
            Created by NSP SOLUTIONS ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default TaskDetails;
