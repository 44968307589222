import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { Breadcrumb, Button, Card, Flex, Layout, Menu, Modal, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../functions/token";
import apiUrl from "../api/axios";
import Meta from "antd/es/card/Meta";
import img from "../img/default.png"
import MenuHeader from "./MenuHeader";
import { Footer } from "antd/es/layout/layout";
import { sidebarItem } from "../functions/sidebar";
const { Content, Sider } = Layout;


const Projects = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleProfileClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleItemClick = (href) => {
    navigate(href);
  };

  useEffect(() => {
    const fetchProjectsOfUser = async () => {
      try {
        const token = getCookie("Authorization");
        const response = await fetch(`${apiUrl}/profile/my-projects`, {
          method: 'GET',
          headers: {
            'Authorization': `${token}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setProjects(data.response.data);
        } else {
          return null;
        }
      } catch (error) {
        console.error("Error fetching projects of user:", error);
      }
    };

    fetchProjectsOfUser();
  }, []);


  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const handleDelete = (id) => {
    setSelectedProjectId(id);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = () => {
    const id = selectedProjectId;
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/profile/project/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        'Authorization': `${token}`,
      },
    })
      .then((res) => {
        return res.json()
      })
      .then((resp) => {
        setProjects(projects.filter((resp) => resp.id !== id));
        navigate("/projects");
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      })
      .finally(() => {
        setDeleteModalVisible(false);
      });
  };

  const handleEdit = (id) => {
    navigate(`/projectEdit/${id}`);
  }

  const handleInfo = (id) => {
    navigate(`/profileprojectdetails/${id}`);
  };

  return (
    <Layout className="main-layout">
      <Modal
        title="Confirm Delete"
        open={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={() => setDeleteModalVisible(false)}
      >
        <p>Are you sure you want to delete this project?</p>
      </Modal>
      <MenuHeader onProfileClick={handleProfileClick} />
      <Layout>
        <Sider width={200} style={{ background: colorBgContainer, display: sidebarOpen ? "block" : "none", }}>
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ borderRight: 0 }}
          >
            {sidebarItem.map((item) => (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                onClick={() => handleItemClick(item.href)}
              >
                {item.label}
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Breadcrumb style={{ margin: "16px 0", fontSize: "1.6rem" }}>
            <Breadcrumb.Item>Projects</Breadcrumb.Item>
          </Breadcrumb>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              overflowY: "auto",
            }}
            className="sub-layout"
          >
            <Flex value="vertical" gap="middle" wrap="wrap" >
              {
                projects ? (
                  projects.map(project => (
                    <Card
                      key={project.id}
                      hoverable
                      style={{ width: 300, marginLeft: 30 }}
                      cover={project.file && !project.file.endsWith('.rar') && !project.file.endsWith('.pdf') && !project.file.endsWith('.zip') ? (
                        <img
                          src={`${apiUrl}/uploads/${project.file}`}
                          style={{ width: "100%", height: "150px" }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={img}
                          style={{ width: "100%", height: "150px" }}
                          alt=""
                        />
                      )}
                      actions={[
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                          <Button
                            type="text"
                            icon={<EditOutlined />}
                            onClick={() => handleEdit(project.id)}
                          ></Button>
                          <Button
                            type="text"
                            primary
                            icon={<InfoCircleOutlined />}
                            onClick={() => handleInfo(project.id)}
                          ></Button>
                          <Button
                            type="text"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => handleDelete(project.id)}
                          ></Button>
                        </div>
                      ]}
                    >
                      <Meta
                        title={project.name}
                        description={
                          <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                            {project.description}
                          </div>
                        }
                      />
                    </Card>
                  ))) : ""
              }
              <Card
                style={{
                  width: 300,
                  marginLeft: 30,
                  textAlign: "center",
                  cursor: "pointer",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center"
                }}
                onClick={() => navigate("/projectAdd")}
              >
                <PlusOutlined style={{ fontSize: 40, margin: 15, color: "grey" }} />
              </Card>
            </Flex>
          </Content>
          <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
            Created by NSP SOLUTIONS ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Projects;
