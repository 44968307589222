import React, { useState } from "react";
import { Button, Form, Input, Layout, Menu, theme, Flex, message } from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const CategoryAdd = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const [category, setCategory] = useState([]);
  const [name, setName] = useState("");
  const [errors, setErrors] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const addCategory = () => {
    let hasError = false;
    const errors = {};
    if (!name) {
      errors.name = "Please fill in Name";
      hasError = true;
    }
    if (hasError) {
      setErrors(errors);
      return;
    }
    if (name) {
      const token = getCookie("Authorization");
      fetch(`${apiUrl}/admin/category`, {
        method: "POST",
        body: JSON.stringify({
          name,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
      })
        .then((res) => {
          if (res.status === 500) {
            console.log("referf");
            messageFunc("error", "This category is available");
          } else {
            return res.json()
          }
        })
        .then((data) => {
          if (data) {
            setCategory((prevCategory) => [...prevCategory, data]);
            setName("");
            navigate("/adminpanel/category");
          }

        });
    }
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Category Add</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  style={{ fontSize: "1.4rem" }}
                  onClick={addCategory}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                overflowY: "auto",
                borderRadius: borderRadiusLG,
              }}
            >
              <Form scroll={{ x: 400 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    placeholder="Name"
                    name="name"
                    onChange={(e) => {
                      setName(e.target.value);
                      setErrors({ ...errors, name: null });
                    }}
                  />
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default CategoryAdd;
