import React, { useState } from "react";
import {
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Button, Flex, Form, Input, Layout, Menu, message, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { clearToken, getCookie } from "../functions/token";
import apiUrl from "../api/axios";
import MenuHeader from "./MenuHeader";
import { Footer } from "antd/es/layout/layout";
import { sidebarItem } from "../functions/sidebar";
const { Content, Sider } = Layout;

const ChangePassword = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const handleProfileClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const symbolRegex = /[@#$%]/;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getCookie("Authorization");

    if (!currentPassword || !newPassword || !confirmNewPassword) {
      messageFunc("warning", "All fields are required");
    } else if (currentPassword === newPassword) {
      messageFunc(
        "warning",
        "New password must be different from the current password"
      );
    } else if (!symbolRegex.test(newPassword)) {
      messageFunc(
        "warning",
        "New password must contain at least one special character"
      );
    } else if (!uppercaseRegex.test(newPassword)) {
      messageFunc(
        "warning",
        "New password must contain at least one uppercase letter"
      );
    } else if (!lowercaseRegex.test(newPassword)) {
      messageFunc(
        "warning",
        "New password must contain at least one lowercase letter"
      );
    }
    else if (newPassword.length < 8) {
      messageFunc("warning", "New password must be at least 8 characters long");
    } else if (newPassword !== confirmNewPassword) {
      messageFunc(
        "warning",
        "New password and confirm password must be the same"
      );
    } else {
      try {
        const res = await fetch(`${apiUrl}/profile/change-password`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            currentPassword,
            newPassword,
            confirmNewPassword,
          }),
        });

        if (res.ok) {
          clearToken()
          navigate("/login")
        } else {
          const errorData = await res.json();
          messageFunc("error", `Error: "Current password false!"`);
        }
      } catch (error) {
        messageFunc("error", "An unexpected error occurred.");
      }
    }
  };

  return (
    <>
      {contextHolder}

      <Layout className="main-layout">
        <MenuHeader onProfileClick={handleProfileClick} />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
            >
              {sidebarItem.map((item) => (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  onClick={() => handleItemClick(item.href)}
                >
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Change Password</p>
              <Flex justify="flex-end" align="center">
                <Button type="primary" onClick={handleSubmit}>
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                overflowY: "auto",
                minHeight: 300,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Form scroll={{ x: 600 }} className="sub-layout">
                <Form.Item
                >
                  <Input.Password
                    placeholder="Current password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    name="currentPassword"
                  />
                </Form.Item>
                <Form.Item
                >
                  <Input.Password
                    placeholder="New password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    onChange={(e) => setNewPassword(e.target.value)}
                    name="newPassword"
                  />
                </Form.Item>
                <Form.Item
                >
                  <Input.Password
                    placeholder="Confirm password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    name="confirmNewPassword"
                  />
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default ChangePassword;
