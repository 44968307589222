import React, { useEffect, useState } from "react";
import { Button, Form, Input, Layout, Menu, theme, Flex, message } from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../functions/token";
import apiUrl from "../../api/axios";
import { useParams } from "react-router-dom/dist";
import AdminHeader from "./AdminHeader";
import { Footer } from "antd/es/layout/layout";
import { sidebarItems } from "../../functions/sidebar";
const { Content, Sider } = Layout;

const CategoryEdit = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { id } = useParams();
  const [categoryData, setCategoryData] = useState({
    id: id,
    name: "",
  });
  const [errors, setErrors] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const messageFunc = (typeMessage, text) => {
    messageApi.open({
      type: typeMessage,
      content: text,
    });
  };

  const validateInputs = () => {
    const errors = {};

    if (!categoryData.name) {
      errors.name = "Please fill in Name";
    }
    return errors;
  };

  useEffect(() => {
    const token = getCookie("Authorization");
    fetch(`${apiUrl}/profile/category/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
    })
      .then((res) => res.json())
      .then((categoryData) => {
        const category = categoryData.response.data;
        setCategoryData({
          name: category.name,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setCategoryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const categoryEdit = () => {
    const token = getCookie("Authorization");
    const errors = validateInputs();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    fetch(`${apiUrl}/admin/category`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        id: id,
        name: categoryData.name,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          messageFunc("error", "This category is available");
        } else {
          return res.json();
        }
      })
      .then((resp) => {
        if (resp) {
          setCategoryData({
            id: id,
            name: categoryData.name,
          });
          navigate("/adminpanel/category");
        }
          
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleItemClick = (href) => {
    navigate(href);
  };

  return (
    <>
      {contextHolder}
      <Layout className="main-layout">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
              display: sidebarOpen ? "block" : "none",
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ borderRight: 0 }}
              items={sidebarItems.map((item) => ({
                ...item,
                onClick: () => handleItemClick(item.href),
              }))}
            />
          </Sider>
          <Layout style={{ padding: "0 2rem 1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBlock: "1rem",
              }}
            >
              <p style={{ fontSize: "1.6rem" }}>Category Edit</p>
              <Flex justify="flex-end" align="center">
                <Button
                  type="primary"
                  style={{ fontSize: "1.4rem" }}
                  onClick={categoryEdit}
                >
                  Save
                </Button>
              </Flex>
            </div>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                overflowY: "auto",
              }}
            >
              <Form scroll={{ x: 400 }} className="sub-layout">
                <Form.Item
                  validateStatus={errors.name ? "error" : ""}
                  help={errors.name}
                >
                  <Input
                    placeholder="Name"
                    value={categoryData.name}
                    name="name"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Form>
            </Content>
            <Footer style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Created by NSP SOLUTIONS ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default CategoryEdit;
