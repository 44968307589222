import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getCookie } from '../functions/token';

const AuthLayout = () => {
  const location = useLocation();
  const token = getCookie("Authorization");

  return token
    ? <Outlet />
    : (
      <Navigate
        to="/login"
        replace
        state={{ from: location }} 
      />
    );
};
export default AuthLayout;
