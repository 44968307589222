import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import apiUrl from "../api/axios";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import ResetValidationBox from './ResetValidationBox';

const ResetPassword = ({ username, otpCode }) => {
    const [resetPassword, setResetPassword] = useState("");
    const [confirmResetPassword, setConfirmResetPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showValidationBox, setShowValidationBox] = useState(false);

    const navigate = useNavigate();
    const inputRef = useRef(null);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        const isValid = validateInputs();
        if (!isValid) return;
        try {
            const response = await fetch(`${apiUrl}/auth/reset-password`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    username: username,
                    otpCode: otpCode,
                    resetPassword: resetPassword,
                    confirmResetPassword: confirmResetPassword
                }),
            });
            const data = await response.json();
            if (response.status === 200) {
                navigate(`/login`);
            } else {
                console.log(data.message || 'Password reset failed.');
            }
        } catch (error) {
            console.error('An error occurred while resetting password.');
        }
    };

    const validateInputs = () => {
        const errors = {};
        let isValid = true;

        if (!resetPassword) {
            errors.resetPassword = 'Please enter your password.';
            isValid = false;
        } 
        if (!confirmResetPassword) {
            errors.confirmResetPassword = 'Please confirm your password.';
            isValid = false;
        } else if (resetPassword !== confirmResetPassword) {
            errors.confirm = 'Passwords do not match.';
            isValid = false;
        }

        setErrorMessage(errors);
        return isValid;
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handlePasswordFocus = () => {
        setShowValidationBox(true);
    };

    const handlePasswordBlur = () => {
        setShowValidationBox(false);
    };

    return (
        <>
            <h1>Reset Password</h1>
            <form onSubmit={handleResetPassword}>
                <div style={{ position: "relative" }}>
                    <input
                        name="password"
                        placeholder={errorMessage.resetPassword || "Password"}
                        id="password-input"
                        onChange={e => setResetPassword(e.target.value)}
                        type={showPassword ? "text" : "password"}
                        className={`reset-password-input ${errorMessage.resetPassword ? 'error' : ''}`}
                        style={{ paddingRight: "40px", width: "100%" }}
                        onFocus={handlePasswordFocus}
                        onBlur={handlePasswordBlur}
                    />
                    <div
                        style={{
                            position: "absolute",
                            right: "5%",
                            top: "50%",
                            transform: "translateY(-50%)",
                            fontSize: 15,
                            cursor: "pointer",
                        }}
                        onClick={togglePasswordVisibility}
                    >
                        {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                    </div>
                    {showValidationBox && (
                        <ResetValidationBox
                            password={resetPassword}
                            show={showValidationBox}
                            inputRef={inputRef}
                        />
                    )}
                </div>
                <div style={{ position: "relative" }}>
                    <input
                        name="confirm"
                        placeholder="Confirm password"
                        onChange={e => setConfirmResetPassword(e.target.value)}
                        type={showConfirmPassword ? "text" : "password"}
                        className={`reset-confirm-password-input ${errorMessage.confirmResetPassword ? 'error' : ''}`}
                        style={{ paddingRight: "40px", width: "100%" }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            right: "5%",
                            top: "41%",
                            transform: "translateY(-50%)",
                            fontSize: 15,
                            cursor: "pointer",
                        }}
                        onClick={toggleConfirmPasswordVisibility}
                    >
                        {showConfirmPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                    </div>
                    <pre className="valid-input" style={{ color: "red", fontSize: 14 }}>
                        {errorMessage.confirm ? errorMessage.confirm : "  "} <br />
                    </pre>
                </div>
                <button type="submit">Reset Password</button>
            </form>
        </>
    );
};

export default ResetPassword;
