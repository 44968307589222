import React, { useEffect, useState } from 'react'
import Package from './Package'
import apiUrl from '../api/axios';

const Education = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`${apiUrl}/site/course`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        const data = response.response.data;
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  if(data.length===0) {
    return null
  }

  return (
    <div className='education-wrapper' id='education'>
      <div className="education">
        <div className="education-title">
          <h3>Steps of Education</h3>
        </div>  
        <div className="package-con">
        {data && data.map(d => {
                return <Package key={d} course={d}/>
            })}
        </div>
    </div>
    </div>

  )
}

export default Education